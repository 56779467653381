import { AFR, ANZ, CSA, EUR, MID, OAS, URA } from '../../utils/energyMapContinentGroupings';

export const getCombinedData = () => {
	var mapTemp = require('@highcharts/map-collection/custom/world-lowres.geo.json');
	var eurcoords: any = [];
	var afrcoords: any = [];
	var midcoords: any = [];
	var anzcoords: any = [];
	var csacoords: any = [];
	var uracoords: any = [];
	var oascoords: any = [];

	// merge shapes
	// var countryCodes = [EUR, ANZ, URA, OAS, MID, AFR, CSA];
	var countryCodes = [EUR, AFR, MID, ANZ, CSA, URA, OAS];

	countryCodes.forEach((codes: any, index: number) => {
		mapTemp?.features.forEach(function (feat: any) {
			if (codes.indexOf(feat.properties['hc-key']) !== -1) {
				if (feat.geometry.type === 'MultiPolygon') {
					if (index == 0) {
						eurcoords = eurcoords.concat(feat.geometry.coordinates);
					}
					if (index == 1) {
						afrcoords = afrcoords.concat(feat.geometry.coordinates);
					}
					if (index == 2) {
						midcoords = midcoords.concat(feat.geometry.coordinates);
					}
					if (index == 3) {
						anzcoords = anzcoords.concat(feat.geometry.coordinates);
					}
					if (index == 4) {
						csacoords = csacoords.concat(feat.geometry.coordinates);
					}
					if (index == 5) {
						uracoords = uracoords.concat(feat.geometry.coordinates);
					}
					if (index == 6) {
						oascoords = oascoords.concat(feat.geometry.coordinates);
					}
				} else {
					if (index == 0) {
						eurcoords = eurcoords.concat([feat.geometry.coordinates]);
					}
					if (index == 1) {
						afrcoords = afrcoords.concat([feat.geometry.coordinates]);
					}
					if (index == 2) {
						midcoords = midcoords.concat([feat.geometry.coordinates]);
					}
					if (index == 3) {
						anzcoords = anzcoords.concat([feat.geometry.coordinates]);
					}
					if (index == 4) {
						csacoords = csacoords.concat([feat.geometry.coordinates]);
					}
					if (index == 5) {
						uracoords = uracoords.concat([feat.geometry.coordinates]);
					}
					if (index == 6) {
						oascoords = oascoords.concat([feat.geometry.coordinates]);
					}
				}
			}
		});
	});

	//eastern europe custom
	var eur = {
		type: 'Feature',
		id: 'EU-custom',
		properties: {
			'hc-group': 'admin0',
			'hc-key': 'eur',
			'hc-a2': 'eur',
			name: 'European Union',
			labelrank: '6',
			'country-abbrev': 'E.U.',
			subregion: 'Central Europe',
			'region-wb': 'Europe & Central Asia',
			'iso-a3': 'eur',
			'iso-a2': 'eur',
			'woe-id': null, // n/a
			continent: 'Europe',
		},
		geometry: {
			type: 'MultiPolygon',
			coordinates: eurcoords,
		},
	};
	mapTemp?.features.push(eur);

	//africa custom
	var afr = {
		type: 'Feature',
		id: 'AF-custom',
		properties: {
			'hc-group': 'admin0',
			'hc-key': 'afr',
			'hc-a2': 'afr',
			name: 'Africa',
			labelrank: '6',
			'country-abbrev': 'Africa',
			subregion: 'Africa',
			'region-wb': 'Africa',
			'iso-a3': 'afr',
			'iso-a2': 'afr',
			'woe-id': null, // n/a
			continent: 'Africa',
		},
		geometry: {
			type: 'MultiPolygon',
			coordinates: afrcoords,
		},
	};
	mapTemp?.features.push(afr);

	//middle east custom
	var mid = {
		type: 'Feature',
		id: 'AF-custom',
		properties: {
			'hc-group': 'admin0',
			'hc-key': 'mid',
			'hc-a2': 'mid',
			name: 'Middle East',
			labelrank: '6',
			'country-abbrev': 'Middle East',
			subregion: 'Middle East',
			'region-wb': 'Middle East',
			'iso-a3': 'mid',
			'iso-a2': 'mid',
			'woe-id': null, // n/a
			continent: 'Middle East',
		},
		geometry: {
			type: 'MultiPolygon',
			coordinates: midcoords,
		},
	};
	mapTemp?.features.push(mid);

	//australasia custom
	var anz = {
		type: 'Feature',
		id: 'ANZ-custom',
		properties: {
			'hc-group': 'admin0',
			'hc-key': 'anz',
			'hc-a2': 'anz',
			name: 'Australasia',
			labelrank: '6',
			'country-abbrev': 'Australasia',
			subregion: 'Australasia',
			'region-wb': 'Australasia',
			'iso-a3': 'anz',
			'iso-a2': 'anz',
			'woe-id': null, // n/a
			continent: 'Australasia',
		},
		geometry: {
			type: 'MultiPolygon',
			coordinates: anzcoords,
		},
	};
	mapTemp?.features.push(anz);

	//central south america custom
	var csa = {
		type: 'Feature',
		id: 'CSA-custom',
		properties: {
			'hc-group': 'admin0',
			'hc-key': 'csa',
			'hc-a2': 'csa',
			name: 'Central & South America',
			labelrank: '6',
			'country-abbrev': 'Central & South America',
			subregion: 'Central & South America',
			'region-wb': 'Central & South America',
			'iso-a3': 'csa',
			'iso-a2': 'csa',
			'woe-id': null, // n/a
			continent: 'Central & South America',
		},
		geometry: {
			type: 'MultiPolygon',
			coordinates: csacoords,
		},
	};
	mapTemp?.features.push(csa);

	//Euraisa and europe custom
	var ura = {
		type: 'Feature',
		id: 'URA-custom',
		properties: {
			'hc-group': 'admin0',
			'hc-key': 'ura',
			'hc-a2': 'ura',
			name: 'Europe & Eurasia',
			labelrank: '6',
			'country-abbrev': 'Europe & Eurasia',
			subregion: 'Europe & Eurasia',
			'region-wb': 'Europe & Eurasia',
			'iso-a3': 'ura',
			'iso-a2': 'ura',
			'woe-id': null, // n/a
			continent: 'Europe & Eurasia',
		},
		geometry: {
			type: 'MultiPolygon',
			coordinates: uracoords,
		},
	};
	mapTemp?.features.push(ura);

	//Euraisa and europe custom
	var oas = {
		type: 'Feature',
		id: 'OAS-custom',
		properties: {
			'hc-group': 'admin0',
			'hc-key': 'oas',
			'hc-a2': 'oas',
			name: 'Other Asia',
			labelrank: '6',
			'country-abbrev': 'Other Asia',
			subregion: 'Other Asia',
			'region-wb': 'Other Asia',
			'iso-a3': 'oas',
			'iso-a2': 'oas',
			'woe-id': null, // n/a
			continent: 'Asia',
		},
		geometry: {
			type: 'MultiPolygon',
			coordinates: oascoords,
		},
	};
	mapTemp?.features.push(oas);

	// var finalData = data.concat(mapDataHover);
};
