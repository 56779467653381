import axios from 'axios';
import fetchCSVData from '../utils/fetchCSVData';

const PocketBase = require('pocketbase/dist/pocketbase.cjs');

// export const baseURL = 'http://127.0.0.1:8090'; //localhost endpoint

// To access Pocketbase Admin webpage
// On Terminal CD to C:\web\React\pocketbase   (where pocketbase.exe exists on local machine) then type:
// ./pocketbase serve
// =========> Displays:
// > Server started at: http://127.0.0.1:8090
//  - REST API: http://127.0.0.1:8090/api/
//  - Admin UI: http://127.0.0.1:8090/_/
// Log on as email:  mt@mef-uk.com ??? or turner14466@gmail.com P/W  ???

export const baseURL = 'https://tangy-gas.pockethost.io';

export const pb = new PocketBase(`${baseURL}`);

export const UserSignIn = async (userData: any) =>
	await axios
		.post(`${baseURL}/api/collections/users/auth-with-password`, userData, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			localStorage.setItem('profileID', res?.data?.record?.id);
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const UserProfileUpdate = async (profileID: any, profileData: any) =>
	await axios
		.post(`${baseURL}/api/collections/userTypes/records`, profileData, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const UserHelpDataUpdate = async (helpData: any) => {
	let token: any = localStorage.getItem('token');

	await axios
		.get(`${baseURL}/api/collections/chatBotData/records?filter=(userID='${helpData?.userID}')`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			if (res?.data?.items?.length > 0) {
				axios
					.patch(`/api/collections/chatBotData/records/${res?.data?.items[0]?.id}`, helpData, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.then((res) => {
						return res?.data;
					})
					.catch((err) => {
						return err.response;
					});
			} else {
				axios
					.post(`${baseURL}/api/collections/chatBotData/records`, helpData, {
						headers: {
							'Content-Type': 'application/json',
							Authorization: token,
						},
					})
					.then((res) => {
						return res?.data;
					})
					.catch((err) => {
						return err.response;
					});
			}
		})
		.catch((err) => {
			return err.response;
		});
};

export const GetUserProfileInfo = async (profileID: any) =>
	await axios
		.get(`${baseURL}/api/collections/users/records/${profileID}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const SendEmailVerification = async (email: any) =>
	await axios
		.post(
			`${baseURL}/api/collections/users/request-verification`,
			{ email: email },
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const ConfirmEmailVerification = async (token: any) =>
	await axios
		.post(`${baseURL}/api/collections/users/confirm-verification`, token, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const SendPasswordResetEmail = async (email: any) =>
	await axios
		.post(
			`${baseURL}/api/collections/users/request-password-reset`,
			{ email: email },
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const ResetPasswordConfirmation = async (resetData: any) =>
	await axios
		.post(`${baseURL}/api/collections/users/confirm-password-reset`, resetData, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const handleSavePortfolioData = async (
	userData: any,
	assetData: any,
	individualData: any,
	portName: any,
	type: any
) => {
	let token: any = localStorage.getItem('token');
	let profileID: any = localStorage.getItem('profileID');
	let tempPortfolios: any = [
		{
			name: portName,
			portfolio_plotting_data: userData,
			assetAllocation_data: assetData,
			portfolio_individual_data: individualData,
		},
	];

	Object.entries(individualData).map(([key, value]: any) => {
		if (assetData[key] == 0 && value?.length > 0) {
			delete individualData[key];
		}
	});

	await axios
		.get(
			`${baseURL}/api/collections/${
				type == 'rtrn' ? 'portRTRNdiy' : 'portESGdiy'
			}/records?filter=(user_id='${profileID}')`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then((res) => {
			if (res?.data?.items?.length > 0) {
				let flag = false;
				let finalUpdatedData: any;
				res?.data?.items[0]?.portfolios?.map((portData: any) => {
					if (portData?.name == portName) {
						portData.portfolio_plotting_data[0].data = userData[0]?.data;
						portData.assetAllocation_data = assetData;
						portData.portfolio_individual_data = individualData;
						flag = true;
					}
				});
				if (!flag) {
					let existingPortfolio = [...res?.data?.items[0]?.portfolios];
					existingPortfolio?.push(tempPortfolios[0]);
					finalUpdatedData = { portfolios: existingPortfolio, user_id: profileID };
				} else {
					finalUpdatedData = { portfolios: res?.data?.items[0]?.portfolios, user_id: profileID };
				}
				axios
					.patch(
						`/api/collections/${type == 'rtrn' ? 'portRTRNdiy' : 'portESGdiy'}/records/${res?.data?.items[0]?.id}`,
						finalUpdatedData,
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: token,
							},
						}
					)
					.then((res) => {
						return res?.data;
					})
					.catch((err) => {
						return err.response;
					});
			} else {
				axios
					.post(
						`${baseURL}/api/collections/${type == 'rtrn' ? 'portRTRNdiy' : 'portESGdiy'}/records`,
						{ portfolios: tempPortfolios, user_id: profileID },
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: token,
							},
						}
					)
					.then((res) => {
						return res?.data;
					})
					.catch((err) => {
						return err.response;
					});
			}
			return 'updated';
		})
		.catch((err) => {
			return err.response;
		});
};

export const handleDeletePortfolioData = async (
	userData: any,
	assetData: any,
	individualData: any,
	portName: any,
	type: any
) => {
	let token: any = localStorage.getItem('token');
	let profileID: any = localStorage.getItem('profileID');
	let tempPortfolios: any = [
		{
			name: portName,
			portfolio_plotting_data: userData,
			assetAllocation_data: assetData,
			portfolio_individual_data: individualData,
		},
	];

	Object.entries(individualData).map(([key, value]: any) => {
		if (assetData[key] == 0 && value?.length > 0) {
			delete individualData[key];
		}
	});

	await axios
		.get(
			`${baseURL}/api/collections/${
				type == 'rtrn' ? 'portRTRNdiy' : 'portESGdiy'
			}/records?filter=(user_id='${profileID}')`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then((res) => {
			if (res?.data?.items?.length > 0) {
				let finalUpdatedData: any;
				res?.data?.items[0]?.portfolios?.map((portData: any, index: number) => {
					if (portData?.name == portName) {
						res?.data?.items[0]?.portfolios.splice(index, 1);
					}
				});
				finalUpdatedData = { portfolios: res?.data?.items[0]?.portfolios, user_id: profileID };
				axios
					.patch(
						`/api/collections/${type == 'rtrn' ? 'portRTRNdiy' : 'portESGdiy'}/records/${res?.data?.items[0]?.id}`,
						finalUpdatedData,
						{
							headers: {
								'Content-Type': 'application/json',
								Authorization: token,
							},
						}
					)
					.then((res) => {
						return res?.data;
					})
					.catch((err) => {
						return err.response;
					});
			}
			return 'updated';
		})
		.catch((err) => {
			return err.response;
		});
};

export const getExistingPortfolios = async (profileID: any, type: any) =>
	await axios
		.get(
			`${baseURL}/api/collections/${
				type == 'rtrn' ? 'portRTRNdiy' : 'portESGdiy'
			}/records?filter=(user_id='${profileID}')`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		.then((res) => {
			return res?.data?.items[0];
		})
		.catch((err) => {
			return err.response;
		});

export const getPermissionInfo = async () =>
	await axios
		.get(`${baseURL}/api/collections/permissions/records`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data?.items;
		})
		.catch((err) => {
			return err.response;
		});

export const getSubscribedOrganisations = async () =>
	await axios
		.get(`${baseURL}/api/collections/subscribers/records?filter=(active=true)`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data?.items;
		})
		.catch((err) => {
			return err.response;
		});

export const GetSubscribedUniversities = async (organisation: any) =>
	await axios
		.get(`${baseURL}/api/collections/univAccess/records?filter=(univ_name='${organisation}')`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data?.items;
		})
		.catch((err) => {
			return err.response;
		});

export const getWorldUniversities = async (country?: any) =>
	await axios
		.get(`https://risknz.mef-uk.com/data/Glossary/world_universities_and_domains.json`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			let chosenCountry = country?.length ? country : 'United Kingdom';
			let finalData = res?.data?.filter((uni: any) => uni?.country == chosenCountry);
			return finalData;
		})
		.catch((err) => {
			return err.response;
		});

export const getExistingSubscriberList = async (organisationName: any) =>
	await axios
		.get(`${baseURL}/api/collections/univAccess/records?filter=(univ_name='${organisationName}')`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			const url = pb.getFileUrl(res?.data?.items[0], res?.data?.items[0]?.students);
			return url;
		})
		.catch((err) => {
			return err.response;
		});

export const getPermissionInfoSingle = async (recordID: any) =>
	await axios
		.get(`${baseURL}/api/collections/permissions/records/${recordID}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const RegisterInterestForSubscription = async (userInfo: any) =>
	await axios
		.post(`${baseURL}/api/collections/interestRegUsers/records`, userInfo, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const getMCQScoreInfoSingle = async (mcqRecordID: any) =>
	await axios
		.get(`${baseURL}/api/collections/mcqScores/records?filter=(user_id='${mcqRecordID}')`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			return res?.data;
		})
		.catch((err) => {
			return err.response;
		});

export const SaveMCQScores = async (scoreInfo: any) =>
	await axios
		.get(`${baseURL}/api/collections/mcqScores/records?filter=(user_id='${scoreInfo?.user_id}')`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
		.then((res) => {
			if (res?.data?.items?.length > 0) {
				axios
					.patch(`${baseURL}/api/collections/mcqScores/records/${res?.data?.items[0]?.id}`, scoreInfo, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.then((res) => {
						return res?.data;
					})
					.catch((err) => {
						return err.response;
					});
			} else {
				axios
					.post(`${baseURL}/api/collections/mcqScores/records`, scoreInfo, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.then((res) => {
						return res?.data;
					})
					.catch((err) => {
						return err.response;
					});
			}
		});
