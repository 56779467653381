import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import TreemapModule from 'highcharts/modules/treemap';
import ExportingModule from 'highcharts/modules/exporting';
import HighchartsTreemap from 'highcharts/modules/treemap';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import {
	countryCodesTradeProds,
	hs_countryMappings,
	hs_countryRegionCodes,
	regionCodeToName,
	regionColors,
	tradeDataType,
} from '../../utils/countryMappingPie';
import {
	productColors,
	productListHS,
	productListSITC,
	tradeData,
	treeMapParentListHS,
	treeMapParentListSITC,
} from '../../utils/utilities';
import { CircularProgress, Typography } from '@material-ui/core';

TreemapModule(Highcharts);
HighchartsTreemap(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);
ExportingModule(Highcharts);

type TradeTreemapProps = {
	year: number;
	country: string;
	targetCountry: string;
	tradeDataDropdown: string;
	tradeDataTypeDropdown: string;
	dataClass: string;
	hsbProductSelectionData: any[];
	hsProductData: any[];
	sitcProductData: any[];
	levelWorldData: any[];
	levelProductDataWorld: any[];
	loading: boolean;
	product: string;
	productData: any[];
	excludeProductsCheckbox: boolean;
	selectedExcludedProducts: number[];
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setTotalValue: React.Dispatch<React.SetStateAction<string>>;
};

const TradeTreemap: React.FC<TradeTreemapProps> = ({
	year,
	country,
	targetCountry,
	tradeDataDropdown,
	tradeDataTypeDropdown,
	dataClass,
	hsbProductSelectionData,
	hsProductData,
	sitcProductData,
	levelWorldData,
	levelProductDataWorld,
	product,
	productData,
	loading,
	excludeProductsCheckbox,
	selectedExcludedProducts,
	setLoading,
	setTotalValue,
}) => {
	const [finalTreeMapData, setFinalTreeMapData] = useState<any[]>([]);
	const chartRef = useRef<HTMLDivElement>(null);

	interface TreemapNode {
		id: string;
		val: number;
		level?: number;
		children?: TreemapNode[];
	}

	interface TreemapLayoutParent {
		x: number;
		y: number;
		width: number;
		height: number;
		val: number;
		direction?: string; // Include other properties as needed
	}

	type CustomLayoutAlgorithm = (this: any, parent: TreemapLayoutParent, children: TreemapNode[]) => any;

	const customLayoutAlgorithm: CustomLayoutAlgorithm = function (this: any, parent, children) {
		const childrenArea: Array<any> = [];

		// Ensure children array is not empty before accessing children[0]
		if (children.length > 0 && (children[0]?.level ?? 0) > 1) {
			return (Highcharts as any).seriesTypes.treemap.prototype.sliceAndDice.call(this, parent, children);
		}

		let pTot;
		let direction = parent.direction;
		let x = parent.x;
		let y = parent.y;
		let width = parent.width;
		let height = parent.height;
		let pX, pY, pW, pH;

		for (const child of children) {
			pTot = parent.width * parent.height * (child.val / parent.val);
			pX = x;
			pY = y;
			pW = pTot / height;
			width = width - pW;

			childrenArea.push({
				x: pX,
				y: pY,
				width: pW,
				height: height,
			});
		}

		const firstIndex = children.findIndex((c: TreemapNode) => c.id === '10');
		const order = Array.from(childrenArea, (_, i) => i);
		const toFirst = order.splice(firstIndex, 1);
		order.unshift(...toFirst);

		for (let i = 1, iEnd = order.length; i < iEnd; i++) {
			const current = order[i];
			const previous = order[i - 1];

			childrenArea[current].x = childrenArea[previous].x + childrenArea[previous].width;
		}

		return childrenArea;
	};

	// Attach the custom layout algorithm to Highcharts
	(function (H: any) {
		if (H.seriesTypes && H.seriesTypes.treemap) {
			H.seriesTypes.treemap.prototype.myCustomAlgorithm = customLayoutAlgorithm;
		}
	})(Highcharts);

	const processProductData = () => {
		if (!productData || productData.length === 0) {
			return;
		}
		setLoading(true);

		// Prepare an array to store the final product data including both parents and children
		const finalproductData: any = [];

		productData
			.filter((item) => item.year === year && item.parent_id == product && item.location_code !== 'WD')
			.forEach((item) => {
				const id = item.location_code;
				const name = hs_countryMappings[id] || id;
				const value = tradeDataDropdown === 'exports' ? item.export_value : item.import_value;
				const share = tradeDataDropdown === 'exports' ? item.export_share : item.import_share;

				// Convert value to billions
				const finalValue = parseFloat((value / 1000000000).toFixed(3));
				const finalShare = share ? parseFloat(share.toFixed(1)) : 0;

				// Get the parent region code (e.g., AM, AS, EU, etc.)
				const parentId = hs_countryRegionCodes[id];
				let parentEntry = finalproductData.find((entry: any) => entry.id === parentId);

				// If parent doesn't exist, create a new parent entry
				if (!parentEntry) {
					parentEntry = {
						id: parentId,
						name: regionCodeToName[parentId] || parentId, // Use region name or parentId as fallback
						value: 0, // Initialize value
						share: 0, // Initialize share
						color: regionColors[parentId] || '#000', // Assign fixed color by region
					};
					finalproductData.push(parentEntry); // Add parent entry to final data
				}

				// Add the current child node data to finalproductData
				finalproductData.push({
					parent: parentId, // Reference to the parent
					name: name, // Child name
					value: finalValue, // Child value
					share: share ? share.toFixed(1) + '%' : 'N/A', // Child share
					color: regionColors[parentId] || '#000', // Assign fixed color by region
				});

				// Update the parent's value and share by adding the child's values
				parentEntry.value += finalValue;
				parentEntry.share += finalShare;
			});

		// After processing all children, update the parent's share to a percentage format
		finalproductData.forEach((entry: any) => {
			if (entry.id) {
				entry.share = entry.share.toFixed(1) + '%'; // Convert parent's share to percentage format
			}
		});

		// Calculate total value for 'WD'
		const totalValueShownItem = productData.find(
			(item) => item.year === year && item.parent_id == product && item.location_code === 'WD'
		);
		const totalValueShown = totalValueShownItem
			? parseFloat(
					(
						(tradeDataDropdown === 'exports' ? totalValueShownItem.export_value : totalValueShownItem.import_value) /
						1000000000
					).toFixed(3)
			  )
			: 0;

		setTotalValue(`$${totalValueShown} bn`);
		setFinalTreeMapData(finalproductData);
		setLoading(false);
	};

	const processTreeMapData = () => {
		if (
			!hsbProductSelectionData ||
			hsbProductSelectionData.length === 0 ||
			!hsProductData ||
			hsProductData.length === 0 ||
			!sitcProductData ||
			sitcProductData.length === 0
		) {
			return;
		}
		setLoading(true);
		let filtered: any[] = [];
		let filtered2021Data: any[] = [];

		// Filtering data based on trade type and year
		if (tradeDataTypeDropdown === 'WD') {
			if (year > 2021) {
				filtered2021Data = hsbProductSelectionData.filter(
					(item: any) => item.year === 2021 && item.location_code?.trim() === country
				);
			} else {
				filtered = hsbProductSelectionData.filter(
					(item: any) => item.year === year && item.location_code?.trim() === country
				);
			}
		} else {
			filtered = hsbProductSelectionData.filter((item: any) => {
				const { location_code, partner_code, year: itemYear } = item;
				const isMatchingYear = itemYear === year;
				return location_code?.trim() === country && partner_code?.trim() === targetCountry && isMatchingYear;
			});
		}

		const hsProductMap = new Map<string, any>();
		if (dataClass === 'hs') {
			hsProductData.forEach((product: any) => {
				const { product_id, hs_product_code, hs_product_name_short_en, parent_id } = product;
				if (product_id && hs_product_code && hs_product_name_short_en !== undefined) {
					hsProductMap.set(product_id.trim(), {
						hsProductCode: hs_product_code.replace(/\"/g, '').trim(),
						hsProductName: hs_product_name_short_en.replace(/\"/g, '').trim(),
						parentId: parent_id?.replace(/\"/g, '').trim() || null,
					});
				}
			});
		}

		const sitcProductMap = new Map<string, any>();
		if (dataClass === 'sitc') {
			sitcProductData.forEach((product: any) => {
				const { product_id, sitc_product_code, sitc_product_name_short_en, parent_id } = product;
				if (product_id && sitc_product_code && sitc_product_name_short_en !== undefined) {
					sitcProductMap.set(product_id.trim(), {
						hsProductCode: sitc_product_code.replace(/\"/g, '').trim(),
						hsProductName: sitc_product_name_short_en.replace(/\"/g, '').trim(),
						parentId: parent_id?.replace(/\"/g, '').trim() || null,
					});
				}
			});
		}

		const parentValues: Record<string, number> = {};
		const aggregatedData: Record<string, any> = {};

		// Handling data if year is greater than 2021
		if (year > 2021 && levelWorldData.length > 0) {
			const baseYearData = levelWorldData.find((item: any) => item.Date === 2021);

			if (baseYearData) {
				filtered2021Data.forEach((item: any) => {
					const productId = item.product_id.toString().trim();
					const hsProduct = dataClass === 'hs' ? hsProductMap.get(productId) : sitcProductMap.get(productId);

					if (hsProduct) {
						const parentId = hsProduct.parentId;
						if (parentId !== undefined) {
							const currentYearField =
								tradeDataDropdown === 'exports'
									? `Xn${parentId}$${countryCodesTradeProds[country]}`
									: `Mn${parentId}$${countryCodesTradeProds[country]}`;
							const baseYearField = currentYearField;

							const currentYearValue = parseFloat(
								levelWorldData.find((data: any) => data.Date === year)[currentYearField]
							);
							const baseYearValue = parseFloat(baseYearData[baseYearField]);

							const percentageDiff = ((currentYearValue - baseYearValue) / baseYearValue) * 100;
							const originalValue = parseFloat(item[tradeDataDropdown === 'exports' ? 'export_value' : 'import_value']);
							let adjustedValue = originalValue + originalValue * (percentageDiff / 100);
							adjustedValue = parseFloat((adjustedValue / 1000000000).toFixed(3));
							const key = parentId ? `${parentId}-${hsProduct.hsProductName}` : hsProduct.hsProductName;

							if (aggregatedData[key]) {
								aggregatedData[key].value += adjustedValue;
							} else {
								aggregatedData[key] = {
									name: hsProduct.hsProductName,
									value: adjustedValue,
									parent: parentId,
								};
							}
							parentValues[parentId] = (parentValues[parentId] || 0) + adjustedValue;
						}
					}
				});
			}
		} else {
			filtered.forEach((item: any) => {
				const productId = item.product_id.toString().trim();
				const hsProduct = dataClass === 'hs' ? hsProductMap.get(productId) : sitcProductMap.get(productId);

				if (hsProduct) {
					let value = parseFloat(item[tradeDataDropdown === 'exports' ? 'export_value' : 'import_value']);
					value = parseFloat((value / 1000000000).toFixed(3));
					if (hsProduct.parentId) {
						parentValues[hsProduct.parentId] = (parentValues[hsProduct.parentId] || 0) + value;
					}

					const key = hsProduct.parentId ? `${hsProduct.parentId}-${hsProduct.hsProductName}` : hsProduct.hsProductName;

					if (aggregatedData[key]) {
						aggregatedData[key].value += value;
					} else {
						aggregatedData[key] = {
							name: hsProduct.hsProductName,
							value,
							parent: hsProduct.parentId,
						};
					}
				}
			});
		}

		const result = Object.values(aggregatedData);

		let totalParentValue = 0;
		const parentNodes = (dataClass === 'hs' ? treeMapParentListHS : treeMapParentListSITC).map((parent: any) => {
			const parentValue = parentValues[parent.id] || 0;
			totalParentValue += parentValue;
			return {
				id: parent.id.toString(),
				name: parent.name,
				value: parentValue,
				color: productColors[parent.id] || '#000', // Assign color using the productColors map
			};
		});

		const totalValueShown = `$${totalParentValue.toFixed(3)} bn`;
		setTotalValue(totalValueShown);

		const parentNodesWithShare = parentNodes.map((parent: any) => {
			return {
				...parent,
				share: totalParentValue > 0 ? ((parent.value / totalParentValue) * 100).toFixed(1) + '%' : '0%',
			};
		});

		const resultWithShare = result.map((item: any) => {
			if (item.parent && parentValues[item.parent]) {
				item.share = `${((item.value / parentValues[item.parent]) * 100).toFixed(1)}%`;
			}

			// Assign color to children based on their parent or id
			const color = productColors[item.parent] || productColors[item.name] || '#000';
			return {
				...item,
				color,
			};
		});
		if (resultWithShare.length > 0) {
			let filteredTreeMapData = [...parentNodesWithShare, ...resultWithShare];
			if (excludeProductsCheckbox && selectedExcludedProducts.length > 0) {
				filteredTreeMapData = filteredTreeMapData.filter((item) => {
					const itemId = Number(item.id || item.parent);

					return itemId && !selectedExcludedProducts.includes(itemId);
				});
			} else {
				filteredTreeMapData = [...parentNodesWithShare, ...resultWithShare];
			}
			setFinalTreeMapData(filteredTreeMapData);
		} else {
			console.warn('No data to display in Tree Map');
			setFinalTreeMapData([]);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (chartRef.current) {
			product === '11' ? processTreeMapData() : processProductData();
		}
	}, [
		year,
		country,
		tradeDataDropdown,
		targetCountry,
		tradeDataTypeDropdown,
		dataClass,
		hsbProductSelectionData,
		hsProductData,
		sitcProductData,
		product,
		excludeProductsCheckbox,
		selectedExcludedProducts,
	]);

	useEffect(() => {
		if (year > 2021 && levelWorldData.length > 0) {
			processTreeMapData();
		}
	}, [levelWorldData]);

	useEffect(() => {
		if (!loading && finalTreeMapData.length > 0 && chartRef.current) {
			const processedData = finalTreeMapData.slice(0, 1000).map((item: any, index: number) => {
				if (!item.parent) {
					return { ...item, color: item.color };
				}
				return item;
			});
			Highcharts.chart(chartRef.current, {
				accessibility: {
					exposeAsGroupOnly: true,
					screenReaderSection: {
						beforeChartFormat:
							'<{headingTagName}>{chartTitle}</{headingTagName}><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div>',
					},
				},
				series: [
					{
						animation: false,
						name: 'Export',
						type: 'treemap',
						//layoutAlgorithm: 'strip',
						layoutAlgorithm: tradeDataTypeDropdown === 'WD' && product === '11' ? 'myCustomAlgorithm' : 'strip',
						allowDrillToNode: true,
						dataLabels: [
							{
								enabled: true,
								style: { fontSize: '10px', fontWeight: 'bold', color: 'black' },
								formatter: function () {
									const point = this.point as Highcharts.Point & { share?: string };
									return `${point.name}: (${point.share})`;
								},
							},
						],
						borderColor: 'black',
						levels: [
							{
								level: 1,
								colorByPoint: true,
								dataLabels: {
									enabled: true,
									//align: 'left',
									//verticalAlign: 'top',
									style: { fontSize: '16px', fontWeight: 'bold', color: 'black', textOutline: '2px contrast' },
									formatter: function () {
										const point = this.point as Highcharts.Point & { share?: string };
										return `${point.name}: (${point.share})`;
									},
								},
								borderWidth: 3,
							},
							{
								level: 2,
								borderWidth: 1,
								dataLabels: {
									enabled: true,
									style: { fontSize: '12px', color: 'black', textOutline: 'none' },
								},
							},
						],
						data: processedData,
					} as Highcharts.SeriesTreemapOptions,
				],
				exporting: {
					enabled: true,
				},
				title: {
					text: `
					${
						product == '11'
							? `<span style="color: #5450A0; text-decoration: underline;">${hs_countryMappings[country]}</span> - 
					<span style="color: #5450A0;">${tradeData[tradeDataDropdown]}</span> 
					${tradeDataDropdown === 'exports' ? 'to:' : 'from:'} 
					<span style="color: #5450A0; text-decoration: underline;">${
						tradeDataTypeDropdown === 'WD' ? tradeDataType[tradeDataTypeDropdown] : hs_countryMappings[targetCountry]
					}</span> - 
					Product Mix: <span style="color: #5450A0;">${year}</span>`
							: `Global Shares of World <span style="color: #5450A0; text-decoration: underline;">${
									dataClass === 'hs' ? productListHS[product] : productListSITC[product]
							  }</span> 
							<span style="color: #5450A0; text-decoration: underline;">${
								tradeData[tradeDataDropdown]
							}</span>: <span style="color: #5450A0;">${year}</span>`
					}
				`,
				},
				subtitle: {
					text: `2-digit (${dataClass}) trade product data (UN COMTRADE, IMF) - Share of ${
						product === '11' ? '' : 'World'
					} Total(%) and $bn (on mouse-over)`,
				},
				tooltip: {
					valueDecimals: 3,
					pointFormatter: function (this: Highcharts.Point & { share?: string }) {
						const valueInBillion = this.value ? this.value.toFixed(2) : '0.00';
						const share = this.share ? this.share : 'N/A';
						return `<b>${this.name}</b>: $${valueInBillion} bn (${share})`;
					},
					// positioner: function (labelWidth, labelHeight, point) {
					// 	const treemapPoint = point as unknown as Highcharts.Point & {
					// 		shapeArgs?: { x: number; y: number; width: number; height: number };
					// 		node?: { level?: number };
					// 	};

					// 	// Check if the point is a level 1 (parent) node
					// 	if (treemapPoint.node && treemapPoint.node.level === 1 && treemapPoint.shapeArgs) {
					// 		// Set the tooltip to always appear at the top left for level 1 nodes
					// 		return { x: 10, y: 10 };
					// 	}

					// 	// Default positioning for other levels
					// 	if (treemapPoint.shapeArgs) {
					// 		return {
					// 			x: treemapPoint.shapeArgs.x,
					// 			y: treemapPoint.shapeArgs.y
					// 		};
					// 	}

					// 	// Fallback if shapeArgs are not available
					// 	return { x: 0, y: 0 };
					// },
				},
			} as Highcharts.Options);
		}
	}, [finalTreeMapData, loading, year, country, product]);

	return (
		<>
			{loading ? (
				<div style={{ textAlign: 'center', height: '460px', justifyContent: 'center', alignItems: 'center',}}>
					<CircularProgress />
					<Typography variant="body1" style={{ marginTop: '10px', color: 'rgb(84, 77, 160)' }}>
						Loading Data...
					</Typography>
				</div>
			) : (
				<div
					ref={chartRef}
					style={{
						width: '100%',
						height: '460px',
						top: 0,
						left: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				></div>
			)}
		</>
	);
};

export default TradeTreemap;
