// NB THis is a temp "old" version (pre Auth0 being added) until we can get AuthO working (only works on old Netlify version of App)
// See more advanced file versions: InfoStart_inclAuth0.tsx  and  MCQs_inclAuth0.tsx
import React from 'react';
import { Component, useEffect, useState } from 'react';
import { Box, Button, createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/Layout';
import InfoStartCards from './InfoStartCards/InfoCards';
import { GetUserProfileInfo } from '../pocketbase/constants';
import LoadingScreen from '../components/LoadingScreen';
import AccordionForInfoPage from './InfoStartCards/InfoAccordions';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import SunburstPurpleIcon from '../images/sunburst-purple.jpg';
import SunburstOrangeIcon from '../images/sunburst-orange.jpg';
import SunburstGreenIcon from '../images/Sunburst_Icon_Green.jpg';


import PersonIcon from '@material-ui/icons/Person';
import InfoStartNews from './InfoStartCards/InfoStartNews';
import { emissionsData, energyData, financeData, macroData, otherData } from '../utils/infoPageContent';
// ---------- Import riskNZ screenshot images for carousel below
import MacroMapIcon from '../images/MacroMapScreenshot.jpg';
// import EnergySunburstIcon from '../images/EnergySuburstScreenshot.jpg';
import EnergySunburstIcon from '../images/EnergySuburstScreenshot.jpg';
import MacroSunburstIcon from '../images/MacroSuburstScreenshot.jpg';
import EmissionsPieIcon from '../images/EmissionsPieGreenScreenshot.jpg';
import TPItreeMapIcon from '../images/CI_TPIfirmsTreemapScreenshot.jpg';
// import TPItreeMapIcon from '../images/Treemap_mauve.png';
import theme from '../config/theme';
import { useHistory } from 'react-router-dom';
// EmissionsPieScreenshot.jpg

// MT - Where to position this without causing errors !	- had to use inline styles for Card below
//	const styles = useStyles();

// export default class Tab8 extends Component<unknown, State> {
const InfoPage = () => {
	let state = {
		selectOptions: [],
		id: '',
		titleAuthors: '',
		authors: '',
		title: '',
		modalOpen: false,
		edition: undefined,
	};

	const [userDetails, setUserDetails] = useState<any>({});
	const [loading, setLoading] = useState<any>(false);
	const smUpScreen = useMediaQuery(theme.breakpoints.up('sm'));
	const history = useHistory();

	useEffect(() => {
		let profileID: any = localStorage.getItem('profileID');
		setLoading(true);
		GetUserProfileInfo(profileID)
			.then((res) => {
				setUserDetails(res);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [localStorage.getItem('profileID')]);

	return (
		<>
			{/* // div width should react to width of displayed data - How ?	 */}
			{loading ? (
				<LoadingScreen />
			) : (
				<Layout>
					<Box display={smUpScreen ? 'flex' : 'block'} justifyContent={'space-between'} marginBottom={1}>
						<Typography style={{ color: '#544DA0', fontSize: smUpScreen ? '26px' : '20px', fontWeight: 'bold' }}>
							{' '}
							riskNZ - MEF Economic/GHG Scenario App
						</Typography>

						{localStorage.getItem('token') ? (
							<Box
								display={'flex'}
								alignItems="center"
								style={{
									border: '1px solid #cccccc',
									borderRadius: '12px',
									paddingRight: '8px',
									marginBottom: smUpScreen ? 0 : '8px',
								}}
							>
								<PersonIcon
									fontSize="small"
									style={{
										color: '#cccccc',
									}}
								/>
								<Typography
									style={{
										color: '#00a0a0',
										fontSize: '14px',
										fontWeight: 600,
										marginLeft: '4px',
										textTransform: 'capitalize',
									}}
								>
									{`${userDetails?.first_name}: ${userDetails?.user_type}`}
								</Typography>
							</Box>
						) : (
							<Box
								display={'flex'}
								alignItems="center"
								style={{
									marginBottom: smUpScreen ? 0 : '8px',
								}}
							>
								<Button
									variant="contained"
									color="primary"
									style={{
										textTransform: 'unset',
										fontWeight: 'bold',
										fontSize: '14px',
										marginLeft: '12px',
										padding: '2px 8px',
									}}
									onClick={() => {
										history.push('/signin');
									}}
								>
									Login
								</Button>
							</Box>
						)}
					</Box>

					{/* <div style={{}}>
					<span style={{ display: 'inline-block', width: '30%' }}>
						{' '}
						<Select options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />{' '}
					</span>
					<span style={{ color: '#544DA0', fontSize: '15px', fontWeight: 'bold' }}>{'  '}Textbook:</span>{' '}
					<span
						style={{ display: 'inline-block', paddingLeft: '10px', width: '60%', backgroundColor: '#f2f0e6', color: '#c14f00', fontWeight: 'bold', fontSize: '17px', textAlign: 'left', border: '0px solid', }}  >
						<strong>
							{this.state.title}&nbsp;&nbsp;{this.state.authors}
						</strong>{' '}
						&nbsp;&nbsp;<strong>{this.state.edition}</strong>
					</span>
				</div> */}

					<Grid container spacing={2}>
						{/* <Grid item xs={12} sm={6} md={4}>
							<InfoStartCards
								title={`Welcome: ${userDetails?.first_name}  ${userDetails?.last_name} [${userDetails?.organisation}]`}
								objectFit="fill"
								subTitle={`Access Level: ${userDetails?.user_type}-${userDetails?.stream} `}
								body={`
											<ul>
											<li>you have access to the following Tabs: <b>[Macro]</b></li>
											<li>your course/CPD textbook is: <b>[Sloman]</b>.</li>
											<li>if you would like access to restricted Tabs, please tick this box(es) below & we will forward this expression of interest
											to your Organisation:</li> 
											&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Energy: <b>x</b>&nbsp;&nbsp;&nbsp;
											&nbsp;&nbsp;&nbsp;Climate: <b>x</b>&nbsp;&nbsp;&nbsp;
											&nbsp;&nbsp;&nbsp;Finance: <b>x</b>
											<ul>`}
							/>
						</Grid> */}
						<Grid item xs={12} md={9}>
							<InfoStartCards
								title="<strong>Why?</strong> - riskNZ fills crucial gaps in academic/SME knowledge around Economics/Net Zero risks"
								imgSrc={[SunburstGreenIcon, SunburstPurpleIcon, SunburstOrangeIcon]}
								objectFit="fill"
								subTitle="riskNZ provides applied data & risk awareness/quantification skills for the drive to Net Zero carbon - see <a href='/FAQ'  style='text-decoration:underline'> <strong>FAQ</strong> </a> for details."
								body={`
								<ul>
								<li><strong>Students - </strong></i> riskNZ fills crucial gaps in academic curricula in Economics/Business & Finance;</li>
								<li><strong>SMEs/Firms</strong> - are you aware of which countries:sectors of the firms in your supply chain and their Emissions situation ?</li>
								<ul>`}
							/>
							{/* //  MT Image change to riskNZ screenshots */}
							<InfoStartCards
								// imgSrc={[SunburstPurpleIcon, SunburstPurpleIcon, SunburstPurpleIcon]}
								imgSrc={[MacroMapIcon, MacroSunburstIcon, EmissionsPieIcon, TPItreeMapIcon, EnergySunburstIcon]}
								title={`<strong>What?</strong>- Visualise & "Stress-Test" the Global Economic path to Net Zero`}
								objectFit="fill"
								subTitle="Energy Use, CO2 emmissions, Portfolio returns/ESG ratings are economy-driven but climate modelling often assumes 'straight-line' Global economy.
								<b>riskNZ</b> brings real-world economic models & scenario analysis to the Net Zero decarbonisation challenge - it 
								equips business/student users with vital data awareness & risk quantification:"
								body={`<ul>
										<li>the de-facto economic trajectory to 2050 will likely see many (global) economic/tech "shocks" en-route...</li>
										<li>..users can self-explore typical macro scenarios & their transmission mechanisms across a range of
										pre-configured macro/tech scenarios.</li>
										<li>riskNZ is the"front-end" to the MEF suite of global Economic-Emissions-Finance models used in
										business "stress-testing" and consultancy</li>
										<li>it augments textbooks/courses by outlining <i>globally-consistent</i> impacts of Macroeconomic and
										GreenTech &ldquo;shocks&ldquo; (aka &ldquo;Stress Testing&rdquo;)</li>
										<li>animated maps/charts display shock-impacts across a range of domains: Economic, Energy, Climate/Emissions
										and Financial/ESG</li>
										<li>	all (animated) response maps, charts & tables are downloadable for inclusion in users own reports (eg
										risk or senstitivity analysis)</li>
										<ul>`}
							/>
							<InfoStartCards
								title="<strong>How?</strong> - Guide to use this App for different Subjects/Users"
								imgSrc={[SunburstGreenIcon, SunburstPurpleIcon, SunburstOrangeIcon]}
								objectFit="fill"
								subTitle="riskNZ focuses on 4 distinct, but related, &ldquo;subject streams: &rdquo; <b>Macro</b>,
								<b style='color:rgba(221,110,16,1)'>Energy</b>,
								<b style='color:rgba(46,139,87,1)' >GHG Emissions</b> &
								<b style='color:rgba(0,115,237,1)' >Finance/ESG.</b>"
								body={`
								<ul>
								<li>&ldquo;pre-cooked&rdquo; or &ldquo;ready-reckoner&ldquo; macro/tech scenarios display for student users, grouped by
								shock <i><strong>Type</strong></i> & severity... </li>
								<li> ..business users instead see real-world, contemporary scenarios whereas student users will see "ready-reckoner" scenarios that focus more
								on learning</li>
								<li> impacts can be viewed across the Macroeconomy, Energy Use/Supply, GHG emissions by Nation/Sector/Firm, Portfolio Returns and ESG-ratings </li>
								<li>scenario impacts are presented in both levels and a <strong>&ldquo;change from base&rdquo;</strong> format so the
								magnitude & shape of scenario shock-impacts can be fully grasped.</li>
								<li>the MCQ Tab integrates with relevant course textbook chapter to display Multiple Choice Questions that relate 
								specifically to the scenario being explored...</li>
								<li>..user/student answers are then auto-scored on submission for shock-specific, real-time, self-paced learning.</li>
								<ul>`}
							/>
							<hr />
							{localStorage.getItem('token') ? (
								// Render AccordionForInfoPage if there are access rights
								<AccordionForInfoPage
									title="Subject Stream:"
									categoryDescription="Description:"
									header={true}
									color="#544DA0"
								/>
							) : (
								// Render the <h5> element if there are no access rights
								<h5
									style={{
										color: '#544DA0',
										fontSize: '14px',
										fontWeight: 600,
										paddingLeft: '4px',
										textTransform: 'uppercase',
									}}
								>
									Subject Stream/Area -{' '}
									<Box
										component={'span'}
										style={{
											color: 'red',
										}}
									>
										No access rights
									</Box>{' '}
									- Description:
								</h5>
							)}
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('macro') || userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title="Macro"
								body={macroData}
								color="rgba(84,77,160,1)"
								// color="#ffffff"
								// font-weight="bold"
								background="rgba(84,77,160,0.20)"
								categoryDescription="Examine macro/tech scenario impacts on Global/National macroeconomies"
							/>
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('energy') || userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title="Energy"
								body={energyData}
								color="rgba(221,110,16,1)"
								background="rgba(221,110,16,0.2)"
								categoryDescription="Examine macro/tech scenario impacts on Global/National Energy Use/Capacity"
							/>
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('emissions') ||
									userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title="GHG Emissions"
								body={emissionsData}
								color="rgba(46,139,87,1)"
								background="rgba(46,139,87,0.2)"
								categoryDescription="Examine macro/tech scenario impacts on Global/National GHG emissions"
							/>
							<AccordionForInfoPage
								accessIcon={
									userDetails?.stream?.streams?.includes('finance') ||
									userDetails?.stream?.streams?.includes('Full') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title="Finance/ESG"
								body={financeData}
								color="rgba(0,115,237,1)"
								background="rgba(0,115,237,0.2)"
								categoryDescription="Examine macro/tech scenario impacts on Portfolio Returns & ESG ratings"
							/>
							<AccordionForInfoPage
								accessIcon={
									localStorage.getItem('token') ? (
										<CheckIcon style={{ color: 'rgba(38,166,91,1)' }} fontSize="small" />
									) : (
										<ClearIcon style={{ color: 'rgba(255, 0, 0, 1)' }} fontSize="small" />
									)
								}
								title="General"
								body={otherData}
								color="rgba(84,77,160,1)"
								background="rgba(211, 211, 211, 0.3)"
								categoryDescription="See Glossary of Terms and self-test using scenario-specific, textbook MCQs"
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Box>
								<Box
									style={{
										width: '100%',
										height: '100px',
										textAlign: 'center',
										border: '1px solid #cccccc',
										borderRadius: '12px',
										marginBottom: '10px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontSize: '12px',
									}}
								>
									Advertising banner
								</Box>
							</Box>
							<Box>
								<h5
									style={{
										color: '#544DA0',
										fontSize: '14px',
										fontWeight: 600,
										paddingLeft: '4px',
										textTransform: 'uppercase',
									}}
								>
									NEWS FEEDS
								</h5>
								<InfoStartNews />
							</Box>
						</Grid>
					</Grid>
				</Layout>
			)}
		</>
	);
};

export default InfoPage;
