import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import theme from '../../config/theme';
import RegisterYourInterestPromptModal from '../../components/modals/RegisterYourInterest';

interface AccordionProps {
	faqData: any;
	expanded?: boolean;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginBottom: '8px',
	},
	heading: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 'bold',
		// textTransform: 'uppercase',
		paddingLeft: 4,
	},
	headingText: {
		fontSize: theme.typography.pxToRem(13),
	},
}));

const AccordionForFAQPage: React.FC<AccordionProps> = ({ faqData, expanded }) => {
	const classes = useStyles();
	const smUpScreen = useMediaQuery(theme.breakpoints.up('sm'));
	const [expandAccordion, setExpandAccordion] = React.useState<any>(expanded);
	const [openSubscriptionModal, setOpenSubscriptionModal] = React.useState(false);

	const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setExpandAccordion(isExpanded ? panel : false);
	};

	React.useEffect(() => {
		if (expanded) {
			setExpandAccordion('open');
		} else {
			setExpandAccordion(false);
		}
	}, [expanded]);

	return (
		<div className={classes.root}>
			<Accordion
				elevation={1}
				style={{
					border: '1px solid rgba(0, 0, 0, .2)',
					padding: 5,
				}}
				id="faqAccordion"
				expanded={expanded ? (expandAccordion == 'open' ? true : false) : expandAccordion == 'open'}
				onChange={handleChange('open')}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					style={{
						minHeight: 'min-content',
						// height: smUpScreen ? '40px' : '50px',
						padding: 0,
						alignItems: smUpScreen ? 'center' : 'flex-start',
						background: 'rgba(84,77,160,0.05)',
					}}
				>
					<Typography className={classes.heading} color="primary">
						{faqData?.question}
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					style={{
						padding: 0,
						paddingTop: 1,
						display: 'block',
					}}
				>
					<Typography
						style={{
							fontSize: '0.875rem',
						}}
						color="primary"
						dangerouslySetInnerHTML={{ __html: faqData?.answer }}
					></Typography>
					{faqData?.showButton && (
						<Button
							color="primary"
							variant="contained"
							size="small"
							style={{
								textTransform: 'unset',
								margin: '0px 4px 4px',
							}}
							onClick={() => setOpenSubscriptionModal(true)}
						>
							Register here
						</Button>
					)}
				</AccordionDetails>
			</Accordion>
			<RegisterYourInterestPromptModal
				isOpen={openSubscriptionModal}
				handleClose={() => setOpenSubscriptionModal(false)}
			/>
		</div>
	);
};

export default AccordionForFAQPage;
