import React, { useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import Layout from '../../components/Layout';
import AccordionForFAQPage from './FAQAccordions';
import theme from '../../config/theme';

const StudentFAQQuestions = [
	{
		question: 'Why is the riskNZ App needed ?',
		answer: `<ul> 
        <li> <b>Professional Tool</b> - <b>riskNZ</b> or "<b>risk</b>s to <b>N</b>et <b>Z</b>ero" App was originally devised by applied Economists to assist SME/exporters mitigate Global Economic/climate risk; but an educational variant 
		now exists to help fill crucial gaps - around data & policy awareness - in a typical (NeoClassical) Economics course. </li> 
		<li> <b>Educational Gaps</b> - here are some quotes from recent UK University graduates in Economics on the "gaps": <br>
        <div style="padding-left:30px;">
		<b>UEA, Norwich Graduate</b> <i>"..I feel like my degree content was a total waste of time; since starting work I have realised just how little I had been taught about how firms approach constraints & uncertainty in the Global Economy.</i>" <br>
        <b>Manchester Postgraduate </b> <i>"I studied Economics to PhD level but when I entered the Govt. Economic Service (GES) I was forced to teach myself applied/policy economics 
        with the help of Civil Service College - so crazy, just <u>after</u> studying 4 years of academic economics"</i><br>
        <b>Leeds Postgraduate</b>  <i>"I bailed out of my PhD early as it felt like a waste of time/money; I found a job in Finance/ESG where I have learnt so much about 
        Finance/Economics and seems so totally divorced from my Doctoral studies in University Economics"</i>  <br>
        <b>Oxford Postgraduate</b> <i>"algebra algebra algebra that's all we're doing here if you thought that we were talking about the housing crisis ...why 
        your wages don't go up...why you can't get a good job in the City where you grew up  ..we're not...i used to get quite angry ..one of the Professors asked me: 'Why are you so angry ?'
        and I said I'm angry because you're not talking about what's happening in people's lives.."</i>
		</div>
		</li>
        <li> <b>Pluralism</b> - many economic graduates feel equally disillusioned by the "monoculture" NeoClassical theory that <i>still</i> dominates most academic Economic/Finance Depts. to the exclusion of heterodox/empirical 
        approaches that do focus more on these crucial areas...</li>
        <li> <b>Applicability</b> ..this "monoculture" has failed to give students insight into either Financial crises, Economic disequilibrium/uncertainty, Inequality or the crucial role of Energy/Climate</li>
        <li> <b>Applied Risk Skills</b> -..riskNZ helps fill the gap for learners - it is the "front-end" to MEF's suite of applied, Global Macroeconomic, Finance & Energy/Emissions models used for Corporate business risk analysis - it's a full-spec Economic/Business risk tool that
		grounds users in applied risk techniques.</li>
        <li> <b>Enabling Academics</b> - we see riskNZ as a classroom tool to assist those academics struggling to offer an alternative to the NeoClassical dominance in their teaching & research - by empowering students
        with applied tools/skills such as riskNZ we hope to build momentum for a more plural Economics/Finance curriculum that fully integrates Climate. 
        <li> <b>Decarbonisation</b> -the Corporate drive to Net Zero will only happen if we equip our graduates with the relevant data, tools & focus to hit the ground running when entering the labour market;
		it is young people/graduates within companies who will drive the push to NZ  - so their education <i>today</i> is crucial, in fact, existential. </li>
		</ul>`,
	},
	{
		question: 'Where else is riskNZ used and  what is "MEFclub"?',
		answer: `<ul> 
		<li> riskNZ is a real-world, business risk management tool developed from the developer's experience of running the <b>EY ITEM Club</b> </li>
		<li> <b>Education</b> -..an educational variant of riskNZ has been developed to bring such applied risk tools/data into the University classroom.</b> </li>
		<li> <b>Consultancy</b> - the Global Economic, Energy models underlying riskNZ are used regularly in Economic risk analysis for many Global brand Corporates</li>
		<li><b> MEFclubs </b> - are local/regional SME business forecast clubs where firms meet to hear latest Economic, Finance, Emissions outlook and attempt to identify/quantify key business risks in the National/Global economy.</li>
		<li> MEFclubs use the original, "business" version of riskNZ that focuses on organic scenarios rather than ready-reckoner "step" scenarios.</li>
	</ul>`,
	},
	{
		question: 'What tools/data can I access in the free, student version of riskNZ ?',
		answer: `<ul> 
	<li> <b>All Areas</b> -  you can access <i>all</i> Ribbons/Tabs in riskNZ to explore, but within each individual Tab some variable data and scenarios are only accessible with a subscription</li>
	<li> <b>Data Coverage</b> -free access gives users/students a flavour of the range of data & scenario coverage available via subscription.</li>
	<li> <b>Finance</b> - we introduce you to "forward-looking" risk analysis - the "M-C+" method which combines Montecarlo with structural forecasts to 2050.</li>
	<li> <b>Scenarios</b> - the free version of riskNZ has "ready-reckoner" mechanical scenarios wheres the subscription version uses more organic, current business scenarios that address 
	the most pressing (global) economic risks to official Economic projections/outlooks. </li>
	</ul>`,
	},
	{
		question: 'What can I access in the subscription riskNZ version ?',
		answer: `<ul> 
	<li> <b>Scenarios</b> - For your subscribed ribbons you can access <i>all</i> scenarios - both "ready-reckoner" mechanical scenarios and organic business ones</li>
	<li> <b>Data/Downloads</b> - accessible in the App + access the MCQ self-testing to obtain the "Eco Driving License"</li>
	<li> <b>Firm-level data</b> - full access to data/projections that helps translate a Global climate problem "out-there" to the Firms/Brands/Equities on the coalface of the push to Net Zero</li>
	<li> <b>Applied MCQs</b> - written by real-world practitioners & applied Economists who help real firms deal with "radical" business uncertainty</li>
	<li> MEF regularly update the business scenarios available in riskNZ to keep abreast of the current Global Economic risk terrain. </li>
	</ul>`,
	},
	{
		question: 'How does riskNZ prepare me for the job market ?',
		answer: `<ul> 
	<li><b>Knowledge Gaps</b> - by filling these & equipping you with awareness of data and quantification of (macroeconomic) risk</li>
	<li><b>Applied Skills</b> - by equipping you with the ability to competently manipulate a professional business risk tool to help firms fathom the wider, Global risk terrain</li>
	<li><b>Preparation</b> - by giving a grounding in Global/National Energy Use/Generation & GHG emissions - such knowledge has been particularly absent in Economics/Business graduates</li>
	</ul>`,
	},
	{
		question: 'Where else is riskNZ used and  what is "MEFclub"?',
		answer: `<ul> 
	<li> <b> MEFclubs </b>  are local/regional SME business forecast clubs where firms meet to hear latest Economic, Finance, Emissions outlook and attempt to identify/quantify key business risks in the National/Global economy.</li>
	<li>MEFclubs use the original, "business" version of riskNZ that focuses on organic scenarios rather than ready-reckoner "step" scenarios.</li>
	</ul>`,
	},
	{
		question: 'Can I express my interest in getting my University/Department to subscribe ?',
		answer: `<ul> 
	<li>yes please register your interest and MEF will then approach your Institution to demonstrate hard interest..
	<li>..please encourage fellow  students to sign up so you can access the full features and applied training that the full riskNZ version can bring.</li>
	</ul>`,
		showButton: true,
	},
	{
		question: 'What Employer response has there been to riskNZ ?',
		answer: `<ul> 
	<li>[TBC -Mark C + Salford (Gordon/Amberin) + UEA,Kent,LSE [MBS] ]</li>
	</ul>`,
	},
	{
		question: 'Why does MEF use structural models in its analysis and behind riskNZ ?',
		answer: `<ul> 
	<li><b>Data-driven</b> - these are theory-guided but data-driven models, typically estimated using (panel) error-correction techniques that help preclude spurious relationships ....</li>
	<li>..structural models contrast strongly pure micro-theory driven (calibrated) models such as DSGE models used by Central Banks.</li>
	<li>..whilst the latter are more coherent to (a highly questionable) Neo-Classical theory , they are subject to extensive criticsms for 
	lacking coherence to de-facto business/finance & policy reality, data or lived experience of “radical” business uncertainty</li>
	<li>The Bank of England Chief Economist reported to the Parliamentary Treasure Committee (April 2023) that [for forecasting] its [DSGE] models could not be <i>"trusted"</i> </li>
	<li> <b>Policy Authorities</b> - structural, data-driven, estimated models are used by many prominent policy authorities across Economics & Energy/Emissions; <b>Federal Reserve, OBR, OECD, NIESR, EIA, IEA </b>
	</ul>`,
	},
	{
		question: 'Why climate models are used if at all ?',
		answer: `<ul> 
	<li> <b>No IAMs</b> - riskNZ does <i>not</i> use any Integrated Assessment Models (IAMs) or "DICE" models in the Nordhaus tradition. </li>
	<li> <b>Bottom-Up</b> -the structural models behind riskNZ essentially derive forecasts/scenarios for (ISIC rev4) <i>Industrial <u>Sector output</u></i> or real GVA..</li>
	<li> ..we then project exogenous trends in <i><u>sectoral GHG intensity</u></i> to 2050 and apply these to (endogenous) Sector output forecasts..</li>
	<li><b>Implied Emissions</b> -..the resulting implied <u>GHG emissions levels</u> by Sector are then aggregated to Nation & Global totals</li>
	<li><b>Macro-Emissions Scenarios</b> - the macroeconomic scenarios in riskNZ typically shock the <i>output drivers</i> of GHG emissions but we also explore (exogenous) shocks to sector GHG Intensity.	eg in the Energy Sector </li>
	</ul>`,
	},
];

const BusinessFAQQuestions = [
	{
		question: 'Why is riskNZ needed ?',
		answer: `<ul> 
		<li> <b>riskNZ</b> or "<b>risk</b>s to <b>N</b>et <b>Z</b>ero" App was originally devised by applied Economists to assist SME/exporters mitigate Global Economic, Trade and Policy risk</li> 
		<li> it does this by identifying/quantifying current, Global, Economic/Policy risks and exploring impacts across the Economy, Energy, Emissions and Finance/ESG. : <br>
		<li> <b>Risk Quantification</b> - riskNZ is risk awareness tool aimed at the following business users grappling with future uncertainty:
		<div style="padding-left:30px;">
		<b>Exporters/SMEs</b> - who need to quantify Global Demand/Cost Risks to robustify business operations/expansion. <br>
		<b>Investment/Fund Managers</b> - who need to "stress-test" ie quantify risks to Portfolio Returns/ESG from (Global) macro shocks<br>
		<b>Energy/Emissions Analysts</b> - who need to test Emissions-Macroeconomic sensitivities across a more plausible range of (Global) macroeconomic outcomes<br>
		<b>Higher Education Institutions</b> - Bring Real-World Risk Models, Tools and Apps into the Lecture theatre & tutorial/lab room</i>
		</div>
		</li>
		<li> <b>Scenario Analysis</b> - MEF's riskNZ App contains a number of these, pre-configured business scenarios that explore "shocks" to key exogenous/policy inputs (eg US Federal Funds interest rate) to quantify sensitivities of official Economic/Energy forecasts</li>
		<li> <b>Current</b> - MEF regularly updates its pre-configured macro scenarios which cover significant current areas of both macro and financial risk at Global and/or UK levels</li>
		<li> <b>Cost Effective</b> - riskNZ provides cost-effective, professional-level risk mitigation tool that would typically only be available for the largest of firms.</li>
		<li> <b>Strategy Stress-Testing</b> -aimed at FDs or CEOs that want to "stress-test" business (expansion) strategies so that they are robust to foreseeable (Global) economic shocks</li>
		<li> <b>Staff training/CPD</b> - preparing staff & upgrading their skills/awareness to mitigate risks en-route to Net Zero</li>
		</ul>`,
	},
	{
		question: 'Where else is riskNZ used ?',
		answer: `<ul> 
		<li> riskNZ is a real-world, business risk management tool developed from the developer's experience of academic teaching and previously running the <b>EY ITEM Club</b> - an applied SME forecast club. </li>
		<li><b>"MEFclubs"</b> - [LINK]  are local/regional SME forecast clubs where firms meet hear the latest Economic, Finance, Emissions outlook and attempt to identify & quantify business risk in the National/Global economy.</li>
		<li>MEFclubs use the "business" version of riskNZ that focuses on organic scenarios rather than ready-reckoner scenarios.</li>
		<li> <b>Education</b> -an educational variant of riskNZ exists to help educators fill crucial curriculum gaps - around data & policy awareness - in a typical (NeoClassical) Economics course. </li> 
		<li> <b>Aimed at SMEs</b> - the Global Economic, Energy models underlying riskNZ are used regularly in Economic risk analysis for many Global brand Corporates; riskNZ makes such 
		premium risk-mitigation tools available to a much wider array of (smaller) organisations & businesses.</li>
		</ul>`,
	},
	{
		question: 'What tools/data can I access in the free, business version of riskNZ ?',
		answer: `<ul> 
	<li> <b>Scenarios</b> - "ready-reckoner" are more mechanical stepped scenarios whereas the subscription version of riSkNZ uses more organic/complete business scenarios that address 
	the most pressing, current, (global) economic risks to official Economic projections/outlooks - across a range of (policy or technology) shocks. </li>
	<li> <b>All Areas </b> - you can access <i>all</i> Ribbons/Tabs in riskNZ to explore, but within each individual Tab some variable data and scenarios are only accessible with a subscription</li>
	<li> <b>Data Range</b> -free access gives users a flavour of the <i>range</i> of data & scenario coverage available via subscription.</li>
	<li> <b>Finance</b> - we introduce you to "forward-looking" risk analysis - the "M-C+" method which combines Montecarlo with structural forecasts to 2050.</li>
	</ul>`,
	},
	{
		question: 'What can I access in the subscription riskNZ version ?',
		answer: `<ul> 
		<li> <b>Scenarios</b> - for your subscribed ribbons, you can access <i>all</i> scenarios - both "ready-reckoner" mechanical scenarios and organic business ones </li>
		<li> <b>Data/Downloads</b> - accessible in the App + access the MCQ self-testing to obtain the "Eco Driving License"</li>
		<li> <b>Firm-level data</b> - full access to data/projections that helps translate a Global climate problem "out-there" to the Firms/Brands/Equities on the coalface of the push to Net Zero</li>
		<li> <b>Applied MCQs</b> - written by real-world practitioners & applied Economists who help real firms deal with "radical" business uncertainty</li>
		<li> <b> Current</b> - MEF regularly update the business scenarios available in riskNZ to keep abreast of the current Global Economic risk terrain. </li>
		<li> <b>CPD</b> - access to MCQ self-testing Tab to obtain the "Eco Driving License" as a form of CPD development.MCQs written by real-world business practitioners & applied Economists to help staff to a better awareness business uncertainty in the drive to Net Zero.</li>
		<li><b>Firm-Mapping</b> - firm-level data that helps translate a Global climate problem "out-there" to the Firms/Brands/Equities on the coalface of the push to Net Zero</li>
		<li><b>Supply Chain Risk</b> - firm-level data also allows riskNZ business users to identify the climate/ESG risks relating to firms in their supply-chain. </li>
		</ul>`,
	},
	{
		question: 'Where else is riskNZ used and  what is "MEFclub"?',
		answer: `<ul> 
	<li> <b> MEFclubs </b>  are local/regional SME business forecast clubs where firms meet to hear latest Economic, Finance, Emissions outlook and attempt to identify/quantify key business risks in the National/Global economy.</li>
	<li>MEFclubs use the original, "business" version of riskNZ that focuses on organic scenarios rather than ready-reckoner "step" scenarios.</li>
	</ul>`,
	},
	{
		question: 'How does riskNZ help our Organisation fully understand Net Zero challenges/risks  ?',
		answer: `<ul> 
		<li> <b>Energy Risks</b> - a modern economy is totally energy-dependent as are all the firms within Industrial Sectors..</li>
		<li>.. awareness of developments in the Global Energy sector will impact all firms going forward.
		<li> <b>Rocky Path to 2050 </b> - the Net Zero transformation will present many risks - and opportunities - en route to 2050</li>
		<li> <b> Staff Awareness/CPD</b> - riskNZ helps equip staff with data awareness and quantification of (macroeconomic) risks - to the economy, Energy use & Emissions, by giving a grounding in Global/National Energy Use/Generation & GHG emissions</li>
	</ul>`,
	},
	{
		question: 'Can I express my interest in getting my Organisation to subscribe ?',
		answer: `<ul> 
	<li>yes please register your interest and we will approach your Organisation to demonstrate hard interest once numbers are sufficient. </li>
	<li>please encourage colleagues to express interest so you can access the full features and applied CPD training that the full riskNZ version can bring.</li>
	</ul>`,
		showButton: true,
	},
	{
		question: 'What response has there been to riskNZ App?',
		answer: `<ul> 
	<li> [Quotes: TBC - Mark C. quote + Aviva]</li>
	</ul>`,
	},
	{
		question: 'Why does MEF use "structural" models in its analysis and behind riskNZ ?',
		answer: `<ul> 
	<li><b>Data-driven</b> - these are theory-guided but data-driven models, typically estimated using (panel) error-correction techniques that help preclude spurious relationships ....</li>
	<li>..structural models contrast strongly pure micro-theory driven (calibrated) models such as DSGE models used by Central Banks.</li>
	<li>..whilst the latter are more coherent to (a highly questionable) Neo-Classical theory , they are subject to extensive criticsms for 
	lacking coherence to de-facto business/finance & policy reality, data or lived experience of “radical” business uncertainty</li>
	<li>The Bank of England Chief Economist reported to the Parliamentary Treasure Committee (April 2023) that [for forecasting] its [DSGE] models could not be <i>"trusted"</i> </li>
	<li> <b>Policy Authorities</b> - structural, data-driven, estimated models are used by many prominent policy authorities across Economics & Energy/Emissions; <b>Federal Reserve, OBR, OECD, NIESR, EIA, IEA </b>
	</ul>`,
	},
	{
		question: 'Why climate models are used if at all ?',
		answer: `<ul> 
		<li> <b>No IAMs</b> - riskNZ does <i>not</i> use any Integrated Assessment Models (IAMs) or "DICE" models in the Nordhaus tradition. </li>
		<li> <b>Bottom-Up</b> -the structural models behind riskNZ essentially derive forecasts/scenarios for <i>Industrial <u>Sector output</u></i> or real GVA..</li>
		<li> ..we then project exogenous trends in <i><u>sectoral GHG intensity</u></i> to 2050 and apply these to (endogenous) Sector output forecasts..</li>
		<li><b>Implied Emissions</b> -..the resulting implied <u>GHG emissions levels</u> by Sector are then aggregated to Nation & Global totals</li>
		<li><b>Macro-Emissions Scenarios</b> - the macroeconomic scenarios in riskNZ typically shock the <i>output drivers</i> of GHG emissions but we also explore (exogenous) shocks to sector GHG Intensity.
		eg in the Energy Sector </li>
		</ul>`,
	},
];

const FAQ = () => {
	const [expandStudentFAQs, setExpandStudentFAQs] = useState<any>(false);
	const [expandBusinessFAQs, setExpandBusinessFAQs] = useState<any>(false);
	const smUpScreen = useMediaQuery(theme.breakpoints.up('md'));

	const handleClickExpandCollapse = (type: string) => {
		if (type == 'student') {
			setExpandStudentFAQs(!expandStudentFAQs);
		}
		if (type == 'business') {
			setExpandBusinessFAQs(!expandBusinessFAQs);
		}
	};

	return (
		<Box>
			<Layout>
				<Grid
					container
					spacing={1}
					style={{
						alignItems: 'center',
						marginBottom: '8px',
					}}
				>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<Typography variant="h5" style={{ color: 'RGBA(84,77,160,1.0)', fontWeight: 'bold' }}>
							Student FAQ&apos;s
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<Box display={'flex'} flexDirection={smUpScreen ? 'row-reverse' : 'row'}>
							<Button
								variant="outlined"
								color="secondary"
								style={{
									fontWeight: 600,
									fontSize: '12px',
									marginRight: '12px',
									padding: '0px 8px',
								}}
								onClick={() => handleClickExpandCollapse('student')}
							>
								{!expandStudentFAQs ? 'Expand all' : 'Collapse all'}
							</Button>
						</Box>
					</Grid>
				</Grid>
				<Grid container spacing={smUpScreen ? 3 : 0}>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<AccordionForFAQPage faqData={StudentFAQQuestions[0]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[1]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[2]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[3]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[4]} expanded={expandStudentFAQs} />
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<AccordionForFAQPage faqData={StudentFAQQuestions[5]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[6]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[7]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[8]} expanded={expandStudentFAQs} />
						<AccordionForFAQPage faqData={StudentFAQQuestions[9]} expanded={expandStudentFAQs} />
					</Grid>
				</Grid>
				<hr />

				<Grid
					container
					spacing={1}
					style={{
						alignItems: 'center',
						marginBottom: '8px',
					}}
				>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<Typography variant="h5" style={{ color: 'RGBA(84,77,160,1.0)', fontWeight: 'bold' }}>
							Business FAQ&apos;s
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<Box display={'flex'} flexDirection={smUpScreen ? 'row-reverse' : 'row'}>
							<Button
								variant="outlined"
								color="secondary"
								style={{
									fontWeight: 600,
									fontSize: '12px',
									marginRight: '12px',
									padding: '0px 8px',
								}}
								onClick={() => handleClickExpandCollapse('business')}
							>
								{!expandBusinessFAQs ? 'Expand all' : 'Collapse all'}
							</Button>
						</Box>
					</Grid>
				</Grid>

				<Grid container spacing={smUpScreen ? 3 : 0}>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<AccordionForFAQPage faqData={BusinessFAQQuestions[0]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[1]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[2]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[3]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[4]} expanded={expandBusinessFAQs} />
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6} direction="column">
						<AccordionForFAQPage faqData={BusinessFAQQuestions[5]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[6]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[7]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[8]} expanded={expandBusinessFAQs} />
						<AccordionForFAQPage faqData={BusinessFAQQuestions[9]} expanded={expandBusinessFAQs} />
					</Grid>
				</Grid>
			</Layout>
		</Box>
	);
};

export default FAQ;
