import * as React from 'react';
import {
	CssBaseline,
	Box,
	Typography,
	TextField,
	Grid,
	Container,
	Button,
	AppBar,
	Toolbar,
	IconButton,
	InputAdornment,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { CloseOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import Logo from '../MEF_Logo_PDC_01_small.jpg';
import { SendEmailVerification, UserSignIn } from '../../pocketbase/constants';
import Snackbar from '@material-ui/core/Snackbar';
import { AppName } from '../../utils/constants';
import AuthHeader from '../AuthHeader';

const SignInForIndividuals = () => {
	const [showPassword, setShowPassword] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [snackbarMessageForError, setSnackbarMessageForError] = React.useState('');

	let history = useHistory();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		const data = new FormData(event.currentTarget);
		const userData = {
			identity: data.get('email'),
			password: data.get('password'),
		};

		UserSignIn(userData)
			.then((res) => {
				if (res?.data && res?.data?.code != 200) {
					setSnackbarMessageForError(res?.data?.message);
					setIsLoading(false);
				} else {
					localStorage.setItem('token', res?.token);
					localStorage.setItem('subscription', res?.record?.subscriptionStatus);
					if (res?.record?.verified) {
						window.location.href = '/info';
						// history.push('/info');
						return;
					} else {
						SendEmailVerification(data.get('email'))
							.then((res) => {
								window.location.href = `/verify-email/${data.get('email')}`;
							})
							.catch((err) => {
								const values: any = Object.values(err?.response?.data?.data);
								const keys: any = Object.keys(err?.response?.data?.data);
								if (values?.length) {
									setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
								} else {
									setSnackbarMessageForError(err?.response.data?.message);
								}
							});
					}
					setIsLoading(false);
				}
			})
			.catch((err) => {
				const values: any = Object.values(err?.response?.data?.data);
				const keys: any = Object.keys(err?.response?.data?.data);
				if (values?.length) {
					setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
				} else {
					setSnackbarMessageForError(err?.response.data?.message);
				}
				setIsLoading(false);
			});
	};

	return (
		<Box>
			<title> Signin | {AppName}</title>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForError?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForError('');
				}}
				id="error"
				message={snackbarMessageForError}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForError('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>
			<CssBaseline />
			<AuthHeader />

			<Box>
				<Box marginTop={10} paddingLeft={2}>
					<Typography
						color="primary"
						variant="h5"
						style={{
							fontWeight: 600,
						}}
					>
						Manchester Economic Forecasting - riskNZ
					</Typography>
				</Box>

				<Container component="main" maxWidth="sm">
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '70vh',
						}}
					>
						<Box
							style={{
								border: '1px solid #cccccc ',
								borderRadius: '12px',
								boxShadow: '10px 10px 8px #efefef',
								padding: '20px 24px',
							}}
						>
							<Typography component="h1" variant="h6">
								Login here
							</Typography>
							<Box component="form" onSubmit={handleSubmit}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Organisation Email"
									name="email"
									autoFocus
									autoComplete="email"
								/>

								<TextField
									required
									fullWidth
									name="password"
									margin="normal"
									label="Organisation Password"
									type={showPassword ? 'text' : 'password'}
									id="password"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													size="small"
												>
													{showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									style={{
										margin: '20px 0px 4px',
									}}
									disabled={isLoading}
								>
									Sign in
								</Button>
								<Grid container spacing={1}>
									<Grid
										item
										xs={6}
										style={{
											marginTop: '12px',
										}}
									>
										<Link to="/forgot-password">
											<Typography variant="body2">Forgot/Reset Password?</Typography>
										</Link>
									</Grid>

									<Grid
										item
										xs={6}
										style={{
											marginTop: '12px',
											textAlign: 'right',
										}}
									>
										<Link to="/signup">
											<Typography variant="body2">Don&apos;t have an account? Sign up</Typography>
										</Link>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Box>
	);
};

export default SignInForIndividuals;
