import { Card, CardMedia, CardContent, Typography, Grid, Grow, Box, useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import Carousel from 'react-material-ui-carousel';
import theme from '../../config/theme';

interface CardProps {
	imgSrc?: any | [];
	title: string;
	subTitle: string;
	body: string;
	objectFit?: string;
	borderColor?: string;
}

const InfoStartCards: React.FC<CardProps> = ({ imgSrc, title, subTitle, body, objectFit, borderColor }) => {
	const [showMore, setShowMore] = React.useState<boolean>(false);
	const smUpScreen = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<Card
			style={{
				boxShadow: '3px 3px 10px rgba(84,77,160,0.3) !important',
				borderRadius: '24px',
				border: '1px solid rgba(84,77,160,0.4)',
				marginBottom: '8px',
				padding: '0px',
			}}
		>
			<CardContent
				style={{
					padding: '0px',
				}}
			>
				<Grid container spacing={1}>
					{!smUpScreen && (
						<Grid
							item
							xs={12}
							style={{
								textAlign: 'center',
								paddingLeft: 0,
								paddingRight: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Box>
								{imgSrc?.length ? (
									<Carousel autoPlay={true} stopAutoPlayOnHover navButtonsAlwaysInvisible={true} indicators={false}>
										{imgSrc?.map((img: any, index: number) => (
											<img
												alt="green iguana"
												height="100"
												width="100%"
												src={img}
												key={index}
												style={{
													objectFit: 'contain',
												}}
											/>
										))}
									</Carousel>
								) : null}
							</Box>
						</Grid>
					)}

					<Grid
						item
						xs={smUpScreen ? 9 : 12}
						style={{
							padding: '12px 0px 12px 16px',
						}}
					>
						<Typography
							variant="h6"
							style={{
								color: '#a08f4d',
								fontSize: '15px',
							}}
							dangerouslySetInnerHTML={{ __html: title }}
						></Typography>
						<Typography
							variant="body2"
							color="primary"
							dangerouslySetInnerHTML={{ __html: subTitle }}
							style={{
								marginBottom: '2px',
								fontSize: '13px',
							}}
						></Typography>
						<Typography
							onClick={() => {
								setShowMore(!showMore);
							}}
							style={{
								marginBottom: '0px',
								fontSize: '12px',
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
						>
							{showMore ? 'Collapse' : 'Expand'}
						</Typography>
					</Grid>
					{smUpScreen && (
						<Grid
							item
							xs={3}
							style={{
								textAlign: 'center',
								paddingLeft: 0,
								paddingRight: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Box>
								{imgSrc?.length ? (
									<Carousel autoPlay={true} stopAutoPlayOnHover navButtonsAlwaysInvisible={true} indicators={false}>
										{imgSrc?.map((img: any, index: number) => (
											<img
												alt="green iguana"
												height="100"
												width="100%"
												src={img}
												key={index}
												style={{
													objectFit: 'contain',
												}}
											/>
										))}
									</Carousel>
								) : null}
							</Box>
						</Grid>
					)}

					{showMore && (
						<Grid item xs={12}>
							<Grow in={true}>
								<Typography
									component={'div'}
									color="primary"
									dangerouslySetInnerHTML={{ __html: body }}
									variant="body2"
									style={{
										fontSize: '14px',
									}}
								></Typography>
							</Grow>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default InfoStartCards;
