import React from 'react';
import { AppBar, Toolbar, Box, Typography } from '@material-ui/core';
import Logo from './MEF_Logo_PDC_01_small.jpg';
import { Link } from 'react-router-dom';

const AuthHeader = () => {
	return (
		<AppBar
			style={{
				padding: 0,
				height: 'max-content',
				color: 'white',
				zIndex: 1,
			}}
			color="inherit"
		>
			<Toolbar>
				<Link to="/">
					<Box display={'flex'} alignItems="center">
						<img src={Logo} alt="logo" width="50px" height="30px" />

						<Typography
							variant="subtitle2"
							noWrap
							color="primary"
							align="left"
							style={{
								textDecoration: 'none',
								lineHeight: '1.25em',
								fontWeight: 600,
								marginLeft: '8px',
							}}
						>
							Identify & Quantify <br /> Macro-Energy-ESG Risk
						</Typography>
					</Box>
				</Link>
			</Toolbar>
		</AppBar>
	);
};

export default AuthHeader;
