import * as React from 'react';
import {
	CssBaseline,
	Box,
	Typography,
	TextField,
	Container,
	Button,
	AppBar,
	Toolbar,
	IconButton,
	InputAdornment,
	useMediaQuery,
} from '@material-ui/core';
import { CloseOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import Logo from '../MEF_Logo_PDC_01_small.jpg';
import Snackbar from '@material-ui/core/Snackbar';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ResetPasswordConfirmation } from '../../pocketbase/constants';
import theme from '../../config/theme';
import AuthHeader from '../AuthHeader';

const ResetPassword = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [snackbarMessageForError, setSnackbarMessageForError] = React.useState('');
	const [snackbarMessageForSuccess, setSnackbarMessageForSuccess] = React.useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const smUpScreen = useMediaQuery(theme.breakpoints.up('sm'));

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};

	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	let { token }: any = useParams();
	let history = useHistory();

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		const data = new FormData(event.currentTarget);
		const userDataForReset = {
			password: data.get('password'),
			passwordConfirm: data.get('passwordConfirm'),
			token: token,
		};
		if (data.get('password') != data.get('passwordConfirm')) {
			setSnackbarMessageForError('Passwords do not match - Please re-enter');
			setIsLoading(false);
			return;
		}
		ResetPasswordConfirmation(userDataForReset)
			.then((res) => {
				if (res?.data?.code == 400) {
					setSnackbarMessageForError(res?.data?.message);
					setIsLoading(false);
					return;
				}
				setSnackbarMessageForSuccess('Password reset successful');
				setTimeout(() => {
					history.replace('/');
				}, 1000);
				setIsLoading(false);
			})
			.catch((err) => {
				const values: any = Object.values(err?.response?.data?.data);
				const keys: any = Object.keys(err?.response?.data?.data);
				if (values?.length) {
					setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
				} else {
					setSnackbarMessageForError(err?.response.data?.message);
				}
				setIsLoading(false);
			});
	};

	return (
		<Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForError?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForError('');
				}}
				id="error"
				message={snackbarMessageForError}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForError('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForSuccess?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForSuccess('');
				}}
				id="success"
				autoHideDuration={4000}
				message={snackbarMessageForSuccess}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForSuccess('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>

			<CssBaseline />
			<AuthHeader />

			<Box>
				<Box marginTop={smUpScreen ? 4 : 10} paddingLeft={2}>
					<Typography
						color="primary"
						variant="h5"
						style={{
							fontWeight: 600,
						}}
					>
						Manchester Economic Forecasting - riskNZ
					</Typography>
				</Box>

				<Container component="main" maxWidth="sm">
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '70vh',
						}}
					>
						<Box
							style={{
								border: '1px solid #cccccc ',
								borderRadius: '12px',
								boxShadow: '10px 10px 8px #efefef',
								padding: '20px 24px',
							}}
						>
							<Typography component="h1" variant="h6">
								Reset Password
							</Typography>
							<Box component="form" onSubmit={handleSubmit}>
								<TextField
									required
									fullWidth
									name="password"
									margin="normal"
									label="New Password"
									type={showPassword ? 'text' : 'password'}
									id="password"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													size="small"
												>
													{showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>

								<TextField
									required
									fullWidth
									name="passwordConfirm"
									margin="normal"
									label="Confirm New Password"
									type={showConfirmPassword ? 'text' : 'password'}
									id="passwordConfirm"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowConfirmPassword}
													onMouseDown={handleMouseDownPassword}
													size="small"
												>
													{showConfirmPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									style={{
										margin: '20px 0px 4px',
									}}
									disabled={isLoading}
								>
									Reset password
								</Button>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Box>
	);
};

export default ResetPassword;
