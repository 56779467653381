import React, { useCallback, useRef, useState } from 'react';
import { Accordion, AccordionDetails, makeStyles, AccordionSummary, Box, Grid, IconButton, Paper, Tooltip, Typography,} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import FirmMappings from '../../utils/firmMappingPie';
import LoadingBackdrop from '../LoadingBackdrop';
import { toPng } from 'html-to-image';

interface Props {
	AccordionBodyDetails: any;
	handleClose: any;
	backgroundColor?: any;
	downloadFileName: any;
}

const years = [2010, 2020, 2030, 2040, 2050];

const CenModalAccordions: React.FC<Props> = ({
	AccordionBodyDetails,
	handleClose,
	downloadFileName,
	backgroundColor,
}) => {
	const useStyles = makeStyles((theme) => ({
		modal: { display: 'flex', alignItems: 'center', justifyContent: 'left', outline: 0 },
		guide: { color: '#a08f4d', fontWeight: 'bold', fontSize: '14px' },
		accSumm: {
			backgroundColor: '#F8F8F8',
			color: '#ffffff',
			fontWeight: 'bold',
			fontSize: '15px',
			margin: 0,
			minHeight: 40,
			'& .MuiAccordionSummary-content': {
				display: 'flex !important',
				justifyContent: 'space-between',
			},
			'&.MuiAccordionSummary-root.Mui-expanded': {
				minHeight: '34px',
			},
			'& .MuiAccordionSummary-content.Mui-expanded': {
				margin: 0,
			},
		},
		accDetail: {
			color: backgroundColor ? backgroundColor : '#544DA0',
			fontWeight: 'normal',
			fontSize: '14px',
			padding: 0,
			margin: 0,
		},
		accTitle: {
			border: '0px solid',
			backgroundColor: backgroundColor ? backgroundColor : '#544DA0',
			color: '#ffffff',
			fontWeight: 'bold',
			fontSize: '14px',
			padding: 0,
			margin: 0,
		},
		accSubTitle: {
			color: backgroundColor ? backgroundColor : '#544DA0',
			fontSize: '12px',
		},
		body: {
			maxWidth: '730px',
			width: '100%',
			backgroundColor: '#F8F8F8',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(1, 1, 1),
			overflow: 'auto',
			maxHeight: '565px',
		},
		closeButton: { display: 'flex' },
		tableData: {
			padding: '0px 5px',
		},
	}));

	const styles = useStyles();
	const [loading, setLoading] = useState<boolean>(false);

	const ref = useRef<any>(null);
	const getDatesArray = (dates: any) => {
		let tempArr: any = [];
		years?.map((singleYear: any, index: number) => {
			dates?.map((singleDate: any) => {
				if (singleDate[`${singleYear}`]) {
					tempArr.push(singleDate[`${singleYear}`]);
				}
			});
		});
		return tempArr;
	};

	const onButtonClick = useCallback(() => {
		setLoading(true);
		if (ref.current === null) {
			setLoading(false);

			return;
		}
		toPng(ref.current, { cacheBust: true })
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.download = `${downloadFileName}.png`;
				link.href = dataUrl;
				link.click();
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, [ref]);

	return (
		<Paper className={styles.body} ref={ref}>
			<LoadingBackdrop loadingState={loading} />
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
				<Typography className={styles.guide}>
					&apos;Central Forecast&apos; detail from which scenario forecasts (sf) are run - Click title to close/open:
				</Typography>

				<Box className={styles.closeButton}>
					<IconButton
						style={{
							padding: '0 4px',
						}}
					>
						<Tooltip title="Download">
							<GetAppIcon
								data-testid="close-button"
								fontSize="small"
								aria-label="close-button"
								onClick={onButtonClick}
							/>
						</Tooltip>
					</IconButton>

					<IconButton
						style={{
							padding: '0 4px',
						}}
					>
						<CloseIcon data-testid="close-button" aria-label="close-button" onClick={handleClose} />
					</IconButton>
				</Box>
			</Box>
			<Grid container spacing={1}>
				{AccordionBodyDetails?.map((accInfo: any, accINdex: number) => (
					<Grid item xs={12} sm={6} md={6} lg={6} key={accINdex}>
						<Accordion
							style={{
								margin: 0,
								padding: 0,
								minHeight: '44px !important',
							}}
							defaultExpanded
						>
							<AccordionSummary className={styles.accSumm}>
								<Typography className={styles.accTitle}>{accInfo?.name}</Typography>
								<Typography className={styles.accSubTitle} align="right">
									{accInfo?.subtitle}
								</Typography>
							</AccordionSummary>
							<AccordionDetails
								style={{
									margin: 0,
									padding: '0 4px',
								}}
							>
								<Typography className={styles.accDetail}>
									<table style={{ padding: '10,10,10,10', border: '1px solid #dddfe1', backgroundColor: '#ffffff' }}>
										<thead style={{ fontWeight: 'bold' }}>
											<tr>
												<td className={styles.tableData}></td>
												<td className={styles.tableData}>2010</td>
												<td className={styles.tableData}>2020</td>
												<td className={styles.tableData}>2030</td>
												<td className={styles.tableData}>2040</td>
												<td className={styles.tableData}>2050</td>
												<td
													className={styles.tableData}
													style={{
														color: '#fff',
													}}
												>
													Sparkline
												</td>{' '}
											</tr>
										</thead>
										<tbody>
											{Object.entries(accInfo?.stateData)?.map((gdpCountryInfo: any, index: number) => (
												<tr style={{ backgroundColor: index % 2 == 0 ? 'rgba(84,77,160,0.2)' : 'initial' }} key={index}>
													<td
														style={{
															fontWeight: 600,
															fontSize: '13px',
														}}
													>
														{gdpCountryInfo[0]?.includes('_')
															? FirmMappings[gdpCountryInfo[0]?.substring(gdpCountryInfo[0]?.indexOf('_') + 1)]?.name
															: 'World'}
													</td>
													{gdpCountryInfo[1]?.map((dates: any, childIndex: number) => (
														<>
															{dates['2010'] && (
																<td
																	key={childIndex}
																	className={styles.tableData}
																	style={{
																		fontWeight:
																			!gdpCountryInfo[0]?.includes('_') || gdpCountryInfo[0]?.includes('WD')
																				? 600
																				: 400,
																	}}
																>
																	{dates['2010'].toFixed(1)}
																</td>
															)}
															{dates['2020'] && (
																<td
																	key={childIndex}
																	className={styles.tableData}
																	style={{
																		fontWeight:
																			!gdpCountryInfo[0]?.includes('_') || gdpCountryInfo[0]?.includes('WD')
																				? 600
																				: 400,
																	}}
																>
																	{dates['2020'].toFixed(1)}
																</td>
															)}
															{dates['2030'] && (
																<td
																	className={styles.tableData}
																	style={{
																		fontWeight:
																			!gdpCountryInfo[0]?.includes('_') || gdpCountryInfo[0]?.includes('WD')
																				? 600
																				: 400,
																	}}
																>
																	{dates['2030'].toFixed(1)}
																</td>
															)}
															{dates['2040'] && (
																<td
																	className={styles.tableData}
																	style={{
																		fontWeight:
																			!gdpCountryInfo[0]?.includes('_') || gdpCountryInfo[0]?.includes('WD')
																				? 600
																				: 400,
																	}}
																>
																	{dates['2040'].toFixed(1)}
																</td>
															)}
															{dates['2050'] && (
																<td
																	className={styles.tableData}
																	style={{
																		fontWeight:
																			!gdpCountryInfo[0]?.includes('_') || gdpCountryInfo[0]?.includes('WD')
																				? 600
																				: 400,
																	}}
																>
																	{dates['2050'].toFixed(1)}
																</td>
															)}
															{dates['2050'] && (
																<td
																	className={styles.tableData}
																	style={{
																		fontWeight:
																			!gdpCountryInfo[0]?.includes('_') || gdpCountryInfo[0]?.includes('WD')
																				? 600
																				: 400,
																	}}
																>
																	<Sparklines data={getDatesArray(gdpCountryInfo[1])}>
																		<SparklinesLine color="black" />
																		{/* <SparklinesReferenceLine type="mean" /> */}
																	</Sparklines>
																</td>
															)}
														</>
													))}
												</tr>
											))}
										</tbody>
									</table>
								</Typography>
							</AccordionDetails>
						</Accordion>
					</Grid>
				))}
			</Grid>

			{/* <Typography style={{ color:'#786c3a',}} >
	To discuss more client-focused, tailored scenarios, please contact us at:  &nbsp;&nbsp;&nbsp;&nbsp;bespoke@mef-uk.com
</Typography> */}
		</Paper>
	);
};

export default CenModalAccordions;
