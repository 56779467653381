import * as React from 'react';
import { CssBaseline, Box, Typography, AppBar, Toolbar, IconButton, Card, Button } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import Logo from '../MEF_Logo_PDC_01_small.jpg';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory, useParams } from 'react-router-dom';
import { GetUserProfileInfo, SendEmailVerification } from '../../pocketbase/constants';
import EmailIcon from '@material-ui/icons/Email';
import LoadingBackdrop from '../LoadingBackdrop';
import AuthHeader from '../AuthHeader';

const VerifyEmailBanner = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [isLoadingButton, setIsLoadingButton] = React.useState(false);
	const [snackbarMessageForError, setSnackbarMessageForError] = React.useState('');
	const [snackbarMessageForSuccess, setSnackbarMessageForSuccess] = React.useState('');
	const [seconds, setSeconds] = React.useState(0);

	let history = useHistory();
	let { email }: any = useParams();

	const handleEmailVerification = async () => {
		setIsLoading(true);
		localStorage.removeItem('verified');
		window.location.href = '/info';
		setIsLoading(false);
	};

	const handleResendClick = () => {
		setIsLoadingButton(true);
		SendEmailVerification(email)
			.then((res) => {
				setSeconds(30);
				setSnackbarMessageForSuccess('Link resent for verification');
				setIsLoadingButton(false);
			})
			.catch((err) => {
				const values: any = Object.values(err?.response?.data?.data);
				const keys: any = Object.keys(err?.response?.data?.data);
				if (values?.length) {
					setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
				} else {
					setSnackbarMessageForError(err?.response.data?.message);
				}
				setIsLoadingButton(false);
			});
	};

	React.useEffect(() => {
		let verifiedFlag = localStorage.getItem('verified');
		if (verifiedFlag && verifiedFlag == 'true') {
			handleEmailVerification();
		}
	}, [localStorage.getItem('verified')]);

	React.useEffect(() => {
		if (seconds == 0) {
			return;
		}
		setTimeout(() => setSeconds(seconds - 1), 1000);
	}, [seconds]);

	return (
		<Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForError?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForError('');
				}}
				id="error"
				message={snackbarMessageForError}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForError('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForSuccess?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForSuccess('');
				}}
				id="success"
				autoHideDuration={4000}
				message={snackbarMessageForSuccess}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForSuccess('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>

			<CssBaseline />
			<AuthHeader />

			<Box>
				<Box marginTop={4} paddingLeft={2}>
					<Typography
						color="primary"
						variant="h5"
						style={{
							fontWeight: 600,
						}}
					>
						Manchester Economic Forecasting - riskNZ
					</Typography>
				</Box>

				{isLoading && <LoadingBackdrop loadingState={isLoading} />}
				<Box marginTop={5}>
					<Card
						style={{
							display: 'block',
							margin: '0 auto',
							maxWidth: '300px',
							padding: '20px 14px',
							textAlign: 'center',
							border: '1px solid rgba(84,77,160,0.4)',
							borderRadius: '14px',
						}}
					>
						<EmailIcon color="primary" />
						<Typography
							color="secondary"
							variant="h6"
							align="center"
							style={{
								fontWeight: 600,
								textTransform: 'uppercase',
								marginTop: '8px',
							}}
						>
							Verify Your Email
						</Typography>

						<Typography
							color="textPrimary"
							variant="body2"
							align="center"
							style={{
								fontSize: '12px',
								marginTop: '8px',
							}}
						>
							Please click on the link sent to your registered email address to gain access to the platform.
						</Typography>
					</Card>
					{seconds == 0 ? (
						<Button
							variant="text"
							style={{
								textTransform: 'unset',
								fontSize: '12px',
								color: 'blue',
								textAlign: 'center',
								display: 'block',
								margin: '16px auto',
							}}
							disabled={isLoadingButton}
							onClick={handleResendClick}
						>
							Resend verification link
						</Button>
					) : (
						<Typography
							align="center"
							style={{
								fontSize: '12px',
								display: 'block',
								margin: '16px auto',
							}}
						>
							00:{seconds}
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default VerifyEmailBanner;
