import React, { useState } from 'react';
import { countryMappings, lineGraphVariables } from '../../utils/constants';
import { ShockVarCodes } from '../../utils/utilities';
import { GetUserProfileInfo, UserHelpDataUpdate } from '../../pocketbase/constants';
import { Grid, Box, FormHelperText, IconButton, InputLabel } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import Highcharts from 'highcharts';
import {
	HighchartsProvider,
	HighchartsChart,
	Loading,
	Title,
	Subtitle,
	Chart,
	Tooltip,
	XAxis,
	PlotLine,
	YAxis,
	LineSeries,
	AreaSeries,
} from 'react-jsx-highcharts';
import ErrorBoundary from '../ErrorBoundary';
// import Modal from '../modals/PortCenModal';
import Modal from '../modals/MacroCenModal';
import GHGMapCenModal from '../modals/GHGCenModal';
import EnergyMapCenModal from '../modals/EnergyCenModal';
import PortMapCenModal from '../modals/PortCenModal';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import scenarios from '../../utils/sfScenarios';
import MapPageVariablesKeyModal from '../modals/MapPageVarAbbrModal';
import BusinessScenarioSizes from '../../utils/bfScenarios';

type CSVData = Record<string, number> & { Date: number };
type LineChartData = {
	name: string;
	data: number[];
	color?: string;
	dashStyle?: any;
	visible?: boolean;
	type?: string;
};

interface Props {
	colourTitle: string;
	dataPrefix: string;
	lineData: LineChartData[];
	selectedCountriesForGraph: CSVData[];
	variable: string[];
	minYear: number;
	colour: string;
	directionRef: any;
	scenarioType: string;
	format: string;
	scenarioSize: string[];
	loading: boolean;
	title: string;
	animationClick: boolean;
	lineGraphVariablesSelected: any;
	type: any;
}
const LineGraphMapPage: React.FC<Props> = ({
	colourTitle,
	dataPrefix,
	lineData,
	selectedCountriesForGraph,
	variable,
	minYear,
	colour,
	directionRef,
	scenarioType,
	format,
	scenarioSize,
	loading,
	title,
	animationClick,
	lineGraphVariablesSelected,
	type,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [openGHGMap, setOpenGHGMap] = useState<boolean>(false);
	const [openEnergyMap, setOpenEnergyMap] = useState<boolean>(false);
	const [openPortMap, setOpenPortMap] = useState<boolean>(false);
	const [loadingChatBot, setLoadingChatBot] = useState<boolean>(false);

	const openModal = (): void => {
		if (dataPrefix == 'MacroMap') {
			setOpen(true);
			return;
		}
		if (dataPrefix == 'GHGMap') {
			setOpenGHGMap(true);
			return;
		}
		if (dataPrefix == 'EnergyMap') {
			setOpenEnergyMap(true);
			return;
		}
		setOpenPortMap(true);
	};

	const handleHelpIconClick = () => {
		setLoadingChatBot(true);
		let profileID: any = localStorage.getItem('profileID');
		let countriesArr: any = [];
		let impactDataArr: any = [];
		let tempLineData: any = lineData;

		selectedCountriesForGraph.map((country: any) => {
			let countryMapping = countryMappings.find((obj) => obj['cnty-suffix'] === country?.suffix);
			const countryName = countryMapping?.['cntyname-mgem'] ?? '';
			countriesArr?.push({
				name: countryName,
				suffix: `_${country?.suffix}`,
			});
		});

		if (lineGraphVariablesSelected?.length) {
			lineGraphVariablesSelected.map((lineVar: any) => {
				lineGraphVariables?.map((varType: any) => {
					if (varType?.value == lineVar) {
						countriesArr?.push({
							name: varType?.label,
							suffix: `_${lineVar}`,
						});
					}
				});
			});
		} else {
			if (variable?.length == 1) {
				countriesArr?.push({
					name: 'World',
					suffix: `_WD`,
				});
			}
		}

		tempLineData?.map((lineValueObj: any, index: any) => {
			lineValueObj?.data?.map((lineValueData: any, childIndex: any) => {
				impactDataArr?.push({
					year: minYear + childIndex,
					value: Number(lineValueData?.value) || lineValueData?.value == 0 ? lineValueData?.value : lineValueData,
				});
			});
			lineValueObj.data = impactDataArr;
			impactDataArr = [];
		});

		let helpObjDetails = {
			shock_type: scenarioType,
			shock_size: scenarioSize,
			shock_variable: ShockVarCodes[scenarioType],
			// output_variable: variable?.substring(variable.lastIndexOf('_') + 1, variable?.length),
			output_variables: variable,
			countries: countriesArr,
			format: format,
			impact_data: tempLineData,
		};

		GetUserProfileInfo(profileID)
			.then((res) => {
				UserHelpDataUpdate({
					userID: res?.id,
					helpAIDetails: helpObjDetails,
					localContextFileDumpName: `${res?.id}_${dataPrefix}.json`,
				})
					.then((resSecondary) => {
						const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
							JSON.stringify({ userID: res?.id, helpAIDetails: helpObjDetails })
						)}`;
						const link = document.createElement('a');
						link.href = jsonString;
						link.download = `${res?.id}_${dataPrefix}.json`;
						link.click();
						setLoadingChatBot(false);
					})
					.catch((err) => {
						setLoadingChatBot(false);
					});
			})
			.catch((err) => {
				setLoadingChatBot(false);
			});
	};

	const [openSourcesVarAbbr, setopenSourcesVarAbbr] = useState<boolean>(false);

	const openSourcesMacroMapModalAbbrVar = (): void => {
		setopenSourcesVarAbbr(true);
	};

	const handleClose = (): void => {
		if (dataPrefix == 'MacroMap') {
			setOpen(false);

			return;
		}
		if (dataPrefix == 'GHGMap') {
			setOpenGHGMap(false);

			return;
		}
		if (dataPrefix == 'EnergyMap') {
			setOpenEnergyMap(false);

			return;
		}
		setOpenPortMap(false);
	};

	return (
		<Grid item xs={12}>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Box>
					<FormHelperText style={{ color: colourTitle }}>
						Click (several) Map Countries to display (compare) time series below:
					</FormHelperText>
					<FormHelperText style={{ color: colourTitle, fontWeight: 'bold', fontSize: '12px' }}>
						Central Forecast (Baseline) Overview{' '}
						<IconButton onClick={() => openModal()} style={{ padding: '0px' }}>
							<Info data-testid="info-button" fontSize="small" />
						</IconButton>
					</FormHelperText>
					<Modal isOpen={open} handleClose={handleClose} />
					<GHGMapCenModal isOpen={openGHGMap} handleClose={handleClose} />
					<EnergyMapCenModal isOpen={openEnergyMap} handleClose={handleClose} />
					<PortMapCenModal isOpen={openPortMap} handleClose={handleClose} />
				</Box>
				{loadingChatBot ? (
					<FormHelperText style={{ color: colourTitle }}>Loading...</FormHelperText>
				) : (
					<IconButton onClick={() => handleHelpIconClick()} style={{ padding: '0px' }}>
						<LiveHelpIcon data-testid="help-button" fontSize="small" />
					</IconButton>
				)}
			</Box>

			<ErrorBoundary>
				<HighchartsProvider Highcharts={Highcharts}>
					<HighchartsChart
						legend={{ enabled: true }}
						containerProps={{ style: { height: '75vh' } }}
						plotOptions={{
							series: {
								animation: animationClick && directionRef == 'play' ? false : true,
							},
						}}
						zoomType="x"
					>
						<Loading isLoading={loading}>Data loading...</Loading>
						<Title style={{ color: colourTitle, fontSize: '14px' }}>{title}</Title>
						<Subtitle style={{ color: '#786c3a' }}>
							{type === 'student'
								? scenarios[scenarioType]?.sizes.find((item: any) => item.id === 'cen')
									? 'Central Forecast(cen)'
									: `Scenario: ${scenarioSize} - ${(() => {
											let text = scenarios[scenarioType].sizes.find(({ id }) => scenarioSize?.includes(id))?.text ?? '';
											text = text.substring(2, text.length);
											return text;
									  })()}`
								: BusinessScenarioSizes[scenarioType]?.sizes.find((item: any) => item.id === 'cen')
								? 'Central Forecast(cen)'
								: `Scenario: ${scenarioSize} - ${(() => {
										let text =
											BusinessScenarioSizes[scenarioType].sizes.find(({ id }) => scenarioSize?.includes(id))?.text ??
											'';
										text = text.substring(2, text.length);
										return text;
								  })()}`}
						</Subtitle>
						<Chart zoomType="x" />
						<XAxis>
							{scenarioType === 'cen' && <PlotLine value={2021} dashStyle="Dash" width={1.5} color="red" />}
						</XAxis>
						<YAxis zoomEnabled>
							{/* <YAxis.Title>
								{format == 'lev' || format == 'LEV' || scenarioType === 'cen'
									? 'Forecast Level'
									: format == 'pct'
									? '% change from Base'
									: variable?.substring(variable.lastIndexOf('_') + 1, variable?.length).startsWith('z')
									? '% point change from Base'
									: 'Absolute change from Base'}
							</YAxis.Title> */}

							<YAxis.Title>
								{format == 'lev' || format == 'LEV' || scenarioType === 'cen'
									? 'Forecast Level'
									: format == 'pct'
									? '% change from Base'
									: 'Absolute change from Base'}
							</YAxis.Title>
							{lineData.length === 1 &&
								lineData.map(({ name, data }, index) => (
									<>
										{directionRef == 'play' ? (
											<LineSeries
												pointStart={minYear}
												key={index}
												name={name}
												data={data}
												// visible={name !== 'World'}
												marker={{ enabled: false }}
												color={colour}
											/>
										) : (
											<AreaSeries
												pointStart={minYear}
												key={index}
												name={name}
												data={data}
												// visible={name !== 'World'}
												marker={{ enabled: false }}
												color={colour}
											/>
										)}
									</>
								))}
							{lineData.length > 1 &&
								lineData.map(({ name, data, color, dashStyle, visible }, index) => (
									<LineSeries
										pointStart={minYear}
										key={index}
										name={name}
										data={data}
										color={color}
										visible={visible}
										dashStyle={dashStyle ? dashStyle : 'Solid'}
										// visible={name !== 'World'}
										marker={{ enabled: false }}
									/>
								))}
						</YAxis>
						<Tooltip />
					</HighchartsChart>
				</HighchartsProvider>
				<InputLabel style={{ float: 'right', color: colourTitle, fontWeight: 'bold', fontSize: '12px' }}>
					Variable Key
					<Info
						data-testid="info-button"
						fontSize="small"
						style={{ cursor: 'pointer' }}
						onClick={() => openSourcesMacroMapModalAbbrVar()}
					/>
				</InputLabel>
				<MapPageVariablesKeyModal
					isOpen={openSourcesVarAbbr}
					handleClose={() => {
						setopenSourcesVarAbbr(false);
					}}
					dataPrefix={dataPrefix}
					color={colourTitle}
					background={colour}
				/>
			</ErrorBoundary>
		</Grid>
	);
};

export default LineGraphMapPage;
