import * as React from 'react';
import { CssBaseline, Box, Typography, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import Logo from '../MEF_Logo_PDC_01_small.jpg';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory, useParams } from 'react-router-dom';
import { ConfirmEmailVerification } from '../../pocketbase/constants';
import { Loading } from 'react-jsx-highcharts';
import AuthHeader from '../AuthHeader';

const EmailVerification = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [snackbarMessageForError, setSnackbarMessageForError] = React.useState('');
	const [snackbarMessageForSuccess, setSnackbarMessageForSuccess] = React.useState('');

	let { token }: any = useParams();
	let history = useHistory();

	const handleConfirmEmailVerification = async () => {
		setIsLoading(true);
		const userDataForVerification = {
			token: token,
		};

		ConfirmEmailVerification(userDataForVerification)
			.then((res) => {
				if (res?.data?.code == 400) {
					setSnackbarMessageForError(res?.data?.message);
					setIsLoading(false);
					return;
				}
				localStorage.setItem('verified', 'true');
				window.location.href = '/info';
				setIsLoading(false);
			})
			.catch((err) => {
				const values: any = Object.values(err?.response?.data?.data);
				const keys: any = Object.keys(err?.response?.data?.data);
				if (values?.length) {
					setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
				} else {
					setSnackbarMessageForError(err?.response.data?.message);
				}
				setIsLoading(false);
			});
	};
	React.useEffect(() => {
		handleConfirmEmailVerification();
	}, []);

	return (
		<Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForError?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForError('');
				}}
				id="error"
				message={snackbarMessageForError}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForError('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForSuccess?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForSuccess('');
				}}
				id="success"
				autoHideDuration={4000}
				message={snackbarMessageForSuccess}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForSuccess('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>

			<CssBaseline />
			<AuthHeader />

			<Box>
				<Box marginTop={4} paddingLeft={2}>
					<Typography
						color="primary"
						variant="h5"
						style={{
							fontWeight: 600,
						}}
					>
						Manchester Economic Forecasting - riskNZ
					</Typography>
				</Box>
				<Loading isLoading={isLoading}>Verifying your email..</Loading>
			</Box>
		</Box>
	);
};

export default EmailVerification;
