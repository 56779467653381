import React, { createContext, useReducer } from 'react';
import PortfolioReducer, { initialState } from './reducer';

export let PortfolioContext = createContext({});

const Provider = ({ children }: any) => {
	const [state, dispatch] = useReducer(PortfolioReducer, initialState);

	const values = {
		state,
		dispatch,
	};

	return (
		<>
			<PortfolioContext.Provider value={values}>{children}</PortfolioContext.Provider>
		</>
	);
};
export default Provider;
