export const macroData = [
	{
		name: 'MacroMap',
		access: false,
		description: 'see how scenarios impact national & Global macroeconomies via impacts charts & heatmaps',
		imgSrc: '',
	},
	{
		name: 'Sunburst',
		access: false,
		description: 'look at Country composition of key Global Macro Variables by Country/Use-sector and to 2050',
		imgSrc: '../../sunburst-purple.jpg',
	},
	{
		name: 'Trade',
		access: false,
		description:
			'view underlying model trade linkages explaining global shock transmissions behind scenarios + global trade links',
		imgSrc: '../../TradeFlowArrowPurpleDark.jpg',
	},
	{
		name: 'MacroTradeProds',
        access: false,
        description: 'Tree Map showing product mix of $bn Exports & Imports by Country-Year across SITC & HS trade classifications',
//      imgSrc: '../../MacroTradeProds.jpg',
        imgSrc: '../../Treemap_mauve.png',
	},
	{
		name: 'mGEM',
		access: false,
		description: 'view underlying model linkages explaining shock transmissions behind scenarios + global trade links',
		imgSrc: '',
	},
];

export const energyData = [
	{
		name: 'EnergyMap',
		access: false,
		description:
			'see how macroeconomic scenarios impact national & Global Energy variables via impacts charts & heatmaps',
		imgSrc: '',
	},
	{
		name: 'Sunburst',
		access: false,
		description: 'look at Country composition of Global Energy Demand by Country/Use-sector and to 2050',
		imgSrc: '../../sunburst-orange.jpg',
	},
	{
		name: 'EnergyTrade',
		access: false,
		description: 'Observe trade partner countries/magnitudes for cross-border, pan-European Electricity flows',
		imgSrc: '../../TradeFlowArrowPurpleDark.jpg',
	},
	{
		name: 'EnergyFlow',
        access: false,
        description: 'Sankey chart displaying Energy Demand by Use-Sector and Energy/Electricity Supply mix to 2050',
        imgSrc: '../../EnergyFlow.jpg',
	},
	{
		name: 'Energy',
		access: false,
		description:
			'drilldown into the composition of Global Energy Demand by Country/Use-sector and to 2050 via Pie & Line charts',
		imgSrc: '',
	},
];
export const emissionsData = [
	{
		name: 'GHGMap',
		access: false,
		description: 'see how scenarios impact national & Global environmental outcomes via impacts charts & heatmaps',
		imgSrc: '',
	},
	{
		name: 'Sunburst',
		access: false,
		description: 'gain overview of climate crisis challenge; view comparative GHG emissions by country and sector',
		imgSrc: '../../sunburst-green.jpg',
	},
	{
		name: 'Industry',
		access: false,
		description: 'drilldown into the composition of overall GHG emissions by GHG gas and Country/Sector and over time',
		imgSrc: '',
	},
	{
		name: 'Firms',
		access: false,
		description: 'drilldown into the composition of overall GHG emissions by GHG gas and Corporates and over time',
		imgSrc: '',
	},
	{
		name: 'Montecarlo',
		access: false,
		description: 'explore risk around GHG emission trajectory to 2050',
		imgSrc: '',
	},
];

export const financeData = [
	{
		name: 'PortfolioMap',
		access: false,
		description: 'see how scenarios impact national & Global investment returns via impacts charts & heatmaps',
		imgSrc: '',
	},
	{
		name: 'ESG-RatingDIY',
		access: false,
		description: 'specify user portfolio asset allocation to generate live, dynamic Esg rating to 2050',
		imgSrc: '',
	},
	{
		name: 'ESG-Portfolio',
		access: false,
		description: 'see how scenarios impact financial portfolio ESG ratings with a Montecarlo risk fan overlay',
		imgSrc: '',
	},
	{
		name: 'Portfolio-DIY',
		access: false,
		description:
			'specify user portfolio asset allocation to generate live, dynamic Returns Forecasts/Scenarios to 2050',
		imgSrc: '',
	},
	{
		name: 'Portfolio',
		access: false,
		description: 'see how scenarios impact financial portfolio returns with a Montecarlo risk fan overlay',
		imgSrc: '',
	},
];
export const otherData = [
	{
		name: 'MCQ',
		access: false,
		description: 'relevant textbook MCQ & applied Task questions that relate to the chosen scenario',
		imgSrc: '',
	},
	{
		name: 'Glossary',
		access: false,
		description: 'an interactive Glossary of all economic, financial & environmental terms',
		imgSrc: '',
	},
	{
		name: 'FAQ',
		access: false,
		description: 'Frequently Asked Questions about riskNZ App',
		imgSrc: '',
	},
];
