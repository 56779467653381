import React, { useEffect, useRef } from 'react';
import Highcharts, { Options } from 'highcharts';
import { hs_countryMappings } from '../../utils/countryMappingPie';
import { productListHS, productListSITC, tradeData } from '../../utils/utilities';

type TradeLineGraphProps = {
	productData: any[];
	tradeDataDropdown: string;
	dataClass: string;
	product: string;
	countryCount: string;
};

const TradeLineGraph: React.FC<TradeLineGraphProps> = ({
	productData,
	tradeDataDropdown,
	dataClass,
	product,
	countryCount,
}) => {
	const chartRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const seriesData: Record<string, { name: string; data: [number, number][]; shareIn2021: number }> = {};

		productData.forEach((item) => {
			if (item.parent_id === Number(product) && item.location_code !== 'WD') {
				const shareValue = tradeDataDropdown === 'exports' ? item.export_share : item.import_share;
				const locationName = hs_countryMappings[item.location_code] || item.location_code;

				if (!seriesData[item.location_code]) {
					seriesData[item.location_code] = {
						name: `${item.location_code} (${locationName})`,
						data: [],
						shareIn2021: 0,
					};
				}

				seriesData[item.location_code].data.push([item.year, shareValue]);

				if (item.year === 2021) {
					seriesData[item.location_code].shareIn2021 = shareValue;
				}
			}
		});

		let sortedSeriesData = Object.values(seriesData).sort((a, b) => b.shareIn2021 - a.shareIn2021);

		if (countryCount !== 'All') {
			sortedSeriesData = sortedSeriesData.slice(0, Number(countryCount));
		}

		if (chartRef.current) {
			const chartOptions: Options = {
				chart: {
					zoomType: 'x',
					type: 'line',
					marginRight: 20,
				},
				title: {
					text: `
                    ${`Global Share of <span style="color: #5450A0; text-decoration: underline;">${
											tradeData[tradeDataDropdown]
										}</span> for <span style="color: #5450A0; text-decoration: underline;">${
											dataClass === 'hs' ? productListHS[product] : productListSITC[product]
										}</span> to 2050`}
                `,
					useHTML: true,
				},
				subtitle: {
					text: `2-digit (${dataClass}) trade product data (UN COMTRADE, IMF) - Share of World Total(%) and $bn (on mouse-over)`,
					useHTML: true,
				},
				xAxis: {
					title: {
						text: 'Year',
					},
					plotLines: [
						{
							value: 2021,
							width: 1.5,
							color: 'red',
							dashStyle: 'Dash',
						},
					],
				},
				yAxis: {
					title: {
						text: 'Global Trade Share of Product (%)',
					},
				},
				tooltip: {
					formatter: function (this: Highcharts.TooltipFormatterContextObject) {
						const point = this.point;
						if (!point) return '';

						const seriesName = point.series?.name ?? 'Unknown';
						const sharePercentage = point.y !== undefined ? point.y.toFixed(2) : '0.00';
						const year = point.x !== undefined ? point.x : 'N/A';

						const dataItem = productData.find(
							(item) =>
								item.year === point?.x &&
								`${item.location_code} (${hs_countryMappings[item.location_code] || item.location_code})` === seriesName
						);

						const value = dataItem
							? tradeDataDropdown === 'exports'
								? dataItem.export_value
								: dataItem.import_value
							: 0;

						const formattedValue = (value / 1e9).toFixed(2);

						return `<b>${seriesName}</b><br/>Year: ${year}<br/>Share: ${sharePercentage}%<br/>${
							tradeDataDropdown === 'exports' ? 'Export' : 'Import'
						} Value: $${formattedValue} bn`;
					},
				},
				legend: {
					align: 'center',
					verticalAlign: 'top',
					layout: 'horizontal',
					maxHeight: 55,
					navigation: {
						enabled: true,
					},
				},
				series: sortedSeriesData.map((series, index) => ({
					name: series.name,
					data: series.data,
					type: 'line',
					marker: { enabled: false },
					color: Highcharts.getOptions().colors?.[index],
				})),
				exporting: {
					enabled: true,
				},
				credits: {
					enabled: false,
				},
				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 500,
							},
							chartOptions: {
								legend: {
									align: 'center',
									verticalAlign: 'bottom',
									layout: 'horizontal',
								},
								yAxis: {
									labels: {
										align: 'right',
										x: 0,
										y: -6,
									},
									title: {
										text: '',
									},
								},
							},
						},
					],
				},
			};

			Highcharts.chart(chartRef.current, chartOptions);
		}
	}, [productData, tradeDataDropdown, product, countryCount]);

	return (
		<div
			ref={chartRef}
			style={{
				width: '100%',
				height: '600px',
			}}
		></div>
	);
};

export default TradeLineGraph;
