import { SAVE_PORTFOLIO } from './constants';

export const initialState = {
	portfolioCount: 0,
};

const PortfolioReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'SAVE PORTFOLIO':
			return {
				// ...state,
				portfolioCount: action.payload,
			};
		case 'DELETE PORTFOLIO':
			return {
				// ...state,
				portfolioCount: action.payload,
			};
		default:
			return state;
	}
};

export default PortfolioReducer;
