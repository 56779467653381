import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Header from './components/Header';
import { GetUserProfileInfo } from './pocketbase/constants';
import { Fab, Tooltip } from '@material-ui/core';
import SignupPromptModal from './components/modals/SignUpPromptModal';
import RegisterYourInterestPromptModal from './components/modals/RegisterYourInterest';
import Highcharts from 'highcharts';
let interval: any;
let subscriptionInterval: any;
interface RouteProps {
	restrictedRoutes: any;
}
const GlobalLayout = (props: RouteProps & React.Component['props']) => {
	const { restrictedRoutes, children } = props; // joined props containing default props

	const location = useLocation();
	const nonAuthRoutes = ['/', '/signup', '/mef/riskNZ', '/forgot-password', '/signin', '/FAQ'];
	const nonAuthParameterRoutes = ['/confirm-password-reset', '/confirm-email-verification', '/verify-email'];
	const history = useHistory();
	const [showHeader, setShowHeader] = useState(false);
	const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
	const [showSignUpModal, setShowSignUpModal] = useState(false);
	const [showSignUpModalFromHomeandFAQ, setShowSignUpModalFromHomeandFAQ] = useState(false);

	let profileID: any = localStorage.getItem('profileID');
	useEffect(() => {
		clearInterval(interval);
		clearInterval(subscriptionInterval);

		if (
			nonAuthRoutes?.includes(location.pathname) ||
			nonAuthParameterRoutes?.includes(location.pathname?.substring(0, location.pathname.lastIndexOf('/')))
		) {
			if (location.pathname == '/FAQ' || location.pathname == '/') {
				setShowHeader(true);
				if (localStorage.getItem('token')) {
					clearInterval(interval);
					clearInterval(subscriptionInterval);
				} else {
					interval = setInterval(() => {
						setShowSignUpModalFromHomeandFAQ(true);
					}, 180000); //show after 3 mins - Reminder signup popup
				}
				return;
			}

			setShowHeader(false);
		} else {
			if (localStorage.getItem('token') && profileID) {
				clearInterval(interval);
				clearInterval(subscriptionInterval);
				GetUserProfileInfo(profileID).then((res) => {
					if (res?.verified == true) {
						setShowHeader(true);
						handleChartSettings(res?.subscriptionStatus);
						if (!res?.interestRegistered) {
							subscriptionInterval = setInterval(() => {
								setShowSubscriptionPopup(true);
							}, 180000); //show after 3 mins - Reminder subscription popup
						}
						return;
					} else {
						setShowHeader(false);
						history.push(`/verify-email/${res?.email}`);
					}
				});
			} else {
				setShowHeader(true);
				setShowSignUpModal(true);
				clearInterval(interval);
				clearInterval(subscriptionInterval);
				// history.push('/');
			}
		}
	}, [location.pathname]);

	const handleChartSettings = (subscriptionStatus: any) => {
		// to remove the export icon from all the maps
		// let chartDom: any = document.getElementsByClassName('highcharts-exporting-group');
		// console.log(chartDom);
		// for (let i = 0; i < chartDom?.length; i++) {
		// 		chartDom[i].style.display = 'none';
		// 	}

		const config = {
			characterDataOldValue: true,
			subtree: true,
			childList: true,
			characterData: true,
		};
		let observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				let oldValue = mutation.oldValue;
				let newValue = mutation.target.textContent;
				let chartMenu: any = document.getElementsByClassName('highcharts-menu');
				if (oldValue !== newValue && chartMenu) {
					if (subscriptionStatus != 'Subscribed') {
						for (let i = 0; i < chartMenu?.length; i++) {
							for (let index = 0; index < chartMenu[i].children?.length; index++) {
								if (index != 0 && index != chartMenu[i].children?.length - 1) {
									chartMenu[i].children[index].style.opacity = '0.5';
									chartMenu[i].children[index].style.pointerEvents = 'none';
								}
							}
						}
					}
				}
			});
		});

		observer.observe(document.body, config);
	};

	return (
		<div>
			{showHeader && <Header restrictedRoutes={restrictedRoutes} />}
			<div>{children}</div>

			<SignupPromptModal
				isOpen={showSignUpModal}
				handleClose={(shouldClose: boolean) => {
					setShowSignUpModal(shouldClose);
				}}
			/>
			<RegisterYourInterestPromptModal
				isOpen={showSubscriptionPopup}
				handleClose={() => {
					setShowSubscriptionPopup(false);
					clearInterval(subscriptionInterval);
				}}
			/>
			<SignupPromptModal
				isOpen={showSignUpModalFromHomeandFAQ}
				handleClose={(shouldClose: boolean) => {
					setShowSignUpModalFromHomeandFAQ(false);
					clearInterval(interval);
				}}
				fromHomeandFAQ
			/>
		</div>
	);
};

export default GlobalLayout;
