import * as React from 'react';
import {
	CssBaseline,
	Box,
	Typography,
	TextField,
	Grid,
	Container,
	Button,
	MenuItem,
	IconButton,
	InputAdornment,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormLabel,
} from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from 'react-router-dom';
import { CloseOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import {
	SendEmailVerification,
	UserSignIn,
	baseURL,
	getPermissionInfo,
	getWorldUniversities,
} from '../../pocketbase/constants';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { useEffect, useState } from 'react';
import AuthHeader from '../AuthHeader';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { countryMappings } from '../../utils/constants';
import { DepartmentNames } from '../../utils/utilities';

const userTypes = [
	{
		value: 'student',
		label: 'Student',
	},
	{
		value: 'business',
		label: 'Business',
	},
	{
		value: 'educator',
		label: 'Educator',
	},
];
let inputOrganisationName: any = '';
let subjectStreamName: any = '';
let inputCountryName: any = '';

const SignUpForIndividuals = () => {
	const [userType, setUserType] = useState('');
	const [streamType, setStreamType] = useState<any>({});
	const [textBook, setTextBook] = useState<any>();
	const [streamsToChoose, setStreamsToChoose] = useState<any>([]);
	const [allotedPermissionTabs, setAllotedPermissionTabs] = useState([]);
	const [textBooksToChoose, setTextBooksToChoose] = useState([]);
	const [permissionTabsDetails, setpermissionTabsDetails] = useState([]);
	const [allOrganisationsDetails, setAllOrganisationsDetails] = useState([]);

	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [snackbarMessageForError, setSnackbarMessageForError] = useState('');
	const [levelOfStudy, setLevelofStudy] = useState('');
	const [inputOrganisation, setInputOrganisation] = useState('');
	const [subjectName, setSubjectName] = useState('');
	const [organisation, setOrganisation] = useState<any>({});
	const [inputCountry, setInputCountry] = useState('');
	const [country, setCountry] = useState<any>({});

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};
	const handleLevelOfStudyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLevelofStudy((event.target as HTMLInputElement).value);
	};

	const handleTextbookChange = (e: any) => {
		setTextBook(JSON.stringify(e.target.value));
	};

	const handleUserTypeChange = (event: any) => {
		setUserType(event.target.value);

		permissionTabsDetails?.forEach((permission: any) => {
			if (permission?.roleType == event.target.value) {
				setAllotedPermissionTabs(permission?.id);
			}
		});
	};
	const filterOptions = createFilterOptions({
		stringify: (option: any) => option?.name,
	});

	const filterCountryOptions = createFilterOptions({
		stringify: (option: any) => option['hc-name'],
	});

	const fetchTextBooks = async () => {
		const res: AxiosResponse<any> = await axios.get(
			'https://risknz.mef-uk.com/csv_ques/MCQ/textbooks/Student_textBooks_01f.json'
		);
		setTextBooksToChoose(res.data);
	};

	const handleProfileUpdate = (userData: any, profileID: any) => {
		const userDataForSignin = {
			identity: userData.get('email'),
			password: userData.get('password'),
		};
		UserSignIn(userDataForSignin)
			.then((res) => {
				localStorage.setItem('token', res?.token);
				localStorage.setItem('subscription', res?.record?.subscriptionStatus);
				SendEmailVerification(userData.get('email'))
					.then((res) => {
						window.location.href = `/verify-email/${userData.get('email')}`;
						setIsLoading(false);
					})
					.catch((err) => {
						const values: any = Object.values(err?.response?.data?.data);
						const keys: any = Object.keys(err?.response?.data?.data);
						if (values?.length) {
							setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
						} else {
							setSnackbarMessageForError(err?.response.data?.message);
						}
						setIsLoading(false);
					});

				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		const data = new FormData(event.currentTarget);

		const userData = {
			email: data.get('email'),
			password: data.get('password'),
			passwordConfirm: data.get('password'),
			first_name: data.get('first_name'),
			last_name: data.get('last_name'),
			user_type: data.get('user_type'),
			levelOfStudy: userType == 'student' ? levelOfStudy : 'N/A',
			subjectOrDepartment: userType == 'business' ? data.get('subject') : streamType?.name,
			stream: {
				streams: ['Full'],
			},
			organisation: userType == 'business' ? data.get('organisation') : organisation?.name,
			country: country['hc-name'],
			textBook,
			permissionTabs: allotedPermissionTabs,
			subscriptionStatus: 'Unsubscribed',
			emailVisibility: true,
			interestRegistered: false,
		};
		let BusinessTextBook = {
			title: 'N/A',
		};
		userData.textBook = userType == 'business' ? JSON.stringify(BusinessTextBook) : JSON.stringify(textBook);
		// send user data to the API endpoint to create a new user in the "users" collection
		await axios
			.post(`${baseURL}/api/collections/users/records`, userData, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				handleProfileUpdate(data, res?.data?.profile?.id);
			})
			.catch((err) => {
				const values: any = Object.values(err?.response?.data?.data);
				const keys: any = Object.keys(err?.response?.data?.data);
				if (values?.length) {
					setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
				} else {
					setSnackbarMessageForError(err?.response.data?.message);
				}
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getPermissionInfo().then((res) => {
			setpermissionTabsDetails(res);
		});
	}, []);
	useEffect(() => {
		if (Object.keys(organisation)?.length) {
			inputOrganisationName = '';
		}
	}, [organisation]);

	useEffect(() => {
		if (Object.keys(streamType)?.length) {
			subjectStreamName = '';
			fetchTextBooks();
		}
	}, [streamType]);

	useEffect(() => {
		if (Object.keys(country)?.length) {
			inputCountryName = '';
			getWorldUniversities(country['hc-name']).then((res: any) => {
				setAllOrganisationsDetails(res);
			});
		}
	}, [country]);

	useEffect(() => {
		if (userType?.length) {
			if (userType == 'student') {
				setStreamsToChoose(DepartmentNames?.subjects);
			}
		}
	}, [userType]);
	return (
		<Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForError?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForError('');
				}}
				id="error"
				message={snackbarMessageForError}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForError('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>
			<CssBaseline />
			<AuthHeader />

			<Box marginTop={10} paddingLeft={2}>
				<Typography
					color="primary"
					variant="h5"
					style={{
						fontWeight: 600,
					}}
				>
					Manchester Economic Forecasting - riskNZ
				</Typography>
			</Box>

			<Container component="main" maxWidth="sm">
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '85vh',
					}}
				>
					<Box
						style={{
							border: '1px solid #cccccc ',
							borderRadius: '12px',
							boxShadow: '10px 10px 8px #efefef',
							padding: '20px 24px',
						}}
					>
						<Typography component="h1" variant="body1">
							Register for free to use riskNZ App
						</Typography>
						<Box component="form" onSubmit={handleSubmit}>
							<Grid container spacing={0}>
								<Grid item xs={12} md={5}>
									<TextField
										style={{
											margin: '8px 0px',
										}}
										required
										fullWidth
										id="first_name"
										label="First Name"
										name="first_name"
										autoFocus
									/>
								</Grid>
								<Grid item xs={12} md={1}></Grid>

								<Grid item xs={12} md={6}>
									<TextField
										style={{
											margin: '8px 0px',
										}}
										required
										fullWidth
										id="last_name"
										label="Last Name"
										name="last_name"
									/>
								</Grid>
							</Grid>

							<FormControl
								required
								fullWidth
								style={{
									margin: '8px 0px',
								}}
							>
								<InputLabel id="user_type">I am a</InputLabel>
								<Select
									labelId="user_type"
									id="user_type"
									value={userType}
									name="user_type"
									onChange={handleUserTypeChange}
									label="I am a"
								>
									{userTypes.map((option: any, index: any) => (
										<MenuItem key={index} value={option?.value}>
											{option?.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{userType == 'student' && (
								<FormControl
									component="fieldset"
									required
									fullWidth
									style={{
										margin: '8px 0px 0px 0px',
									}}
								>
									<FormLabel
										component="legend"
										style={{
											margin: 0,
											fontSize: '12px',
										}}
									>
										Level of study
									</FormLabel>
									<RadioGroup
										aria-label="level"
										name="level"
										value={levelOfStudy}
										onChange={handleLevelOfStudyChange}
										id="level"
										row
									>
										<FormControlLabel
											style={{
												marginBottom: 0,
												fontSize: '14px',
											}}
											value="Undergraduate"
											control={<Radio />}
											label="Undergraduate"
										/>
										<FormControlLabel
											style={{
												marginBottom: 0,
												fontSize: '14px',
											}}
											value="Postgraduate"
											control={<Radio />}
											label="Postgraduate"
										/>
									</RadioGroup>
								</FormControl>
							)}

							<FormControl
								required
								fullWidth
								style={{
									margin: '0px 0px 8px 0px',
								}}
							>
								<Autocomplete
									inputValue={inputCountry}
									onInputChange={(event: any, newInputValue: any | null) => {
										setInputCountry(newInputValue);
										inputCountryName = newInputValue;
									}}
									onChange={(event: any, newValue: any | null) => {
										setInputCountry('');
										inputCountryName = '';
										setCountry(newValue);
									}}
									disableClearable
									disabled={userType?.length == 0}
									options={countryMappings}
									getOptionLabel={(option: any) => option['hc-name']}
									fullWidth
									value={country}
									id="country"
									renderInput={(params) => (
										<TextField {...params} required fullWidth id="country" label="Country" name="country" />
									)}
									filterOptions={filterCountryOptions}
									open={inputCountryName.length > 2 ? true : false}
								/>
							</FormControl>
							{userType == 'business' ? (
								<TextField
									style={{
										margin: '8px 0px',
									}}
									required
									fullWidth
									id="organisation"
									label="Organisation Name"
									name="organisation"
								/>
							) : (
								<FormControl
									required
									fullWidth
									style={{
										margin: '8px 0px',
									}}
								>
									<Autocomplete
										inputValue={inputOrganisation}
										onInputChange={(event: any, newInputValue: any | null) => {
											setInputOrganisation(newInputValue);
											inputOrganisationName = newInputValue;
											// setOrganisation({});
										}}
										onChange={(event: any, newValue: any | null) => {
											setInputOrganisation('');
											inputOrganisationName = '';
											setOrganisation(newValue);
										}}
										disableClearable
										disabled={Object.keys(country)?.length == 0}
										options={allOrganisationsDetails}
										getOptionLabel={(option: any) => option?.name}
										fullWidth
										value={organisation}
										id="organisation"
										renderInput={(params) => (
											<TextField
												{...params}
												required
												fullWidth
												id="organisation"
												label="Organisation Name"
												name="organisation"
											/>
										)}
										filterOptions={filterOptions}
										open={inputOrganisationName.length > 2 ? true : false}
									/>
								</FormControl>
							)}
							{(userType === 'student' || userType == 'business' || userType == 'educator') && (
								<>
									<Grid item xs={12}>
										{userType == 'business' ? (
											<TextField
												style={{
													margin: '8px 0px',
												}}
												required
												fullWidth
												id="subject"
												label="Department Name"
												name="subject"
											/>
										) : (
											<FormControl
												required
												fullWidth
												style={{
													margin: '8px 0px',
												}}
											>
												<Autocomplete
													inputValue={subjectName}
													onInputChange={(event: any, newInputValue: any | null) => {
														setSubjectName(newInputValue);
														subjectStreamName = newInputValue;
													}}
													onChange={(event: any, newValue: any | null) => {
														setSubjectName('');
														subjectStreamName = '';
														setStreamType(newValue);
													}}
													disableClearable
													disabled={userType?.length == 0}
													options={streamsToChoose}
													getOptionLabel={(option: any) => option['name']}
													fullWidth
													value={streamType}
													id="subject"
													renderInput={(params) => (
														<TextField {...params} required fullWidth id="subject" label="Department" name="subject" />
													)}
													filterOptions={filterOptions}
													open={subjectStreamName.length > 2 ? true : false}
												/>
											</FormControl>
										)}
									</Grid>

									{userType === 'student' && (
										<Grid item xs={12}>
											<FormControl
												required
												fullWidth
												style={{
													margin: '8px 0px',
												}}
												disabled={streamType?.length == 0}
											>
												<InputLabel id="textBook">Textbook</InputLabel>
												<Select
													labelId="textBook"
													id="textBook"
													value={textBook}
													name="textBook"
													onChange={handleTextbookChange}
													label="TextBook"
												>
													<MenuItem value="" disabled>
														<em>Select your textbook</em>
													</MenuItem>
													{textBooksToChoose.map((textBookOption: any, index: number) => (
														// @ts-ignore
														<MenuItem key={`tb_${index}`} value={textBookOption}>
															{textBookOption?.title}, {textBookOption?.author}, {textBookOption.publisher},{' '}
															{textBookOption?.edition}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									)}
								</>
							)}

							<TextField
								style={{
									margin: '8px 0px',
								}}
								required
								fullWidth
								id="email"
								label="Organisation Email Address"
								name="email"
								autoComplete="email"
							/>

							<TextField
								required
								fullWidth
								name="password"
								style={{
									margin: '8px 0px',
								}}
								label="Password"
								type={showPassword ? 'text' : 'password'}
								id="password"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												size="small"
											>
												{showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								style={{
									margin: '20px 0px 4px',
								}}
								disabled={isLoading}
							>
								Sign up
							</Button>
							<Grid container spacing={1}>
								<Grid
									item
									style={{
										marginTop: '12px',
									}}
								>
									<Link to="/signin">
										<Typography variant="body2">{'Already have an account? Sign in'}</Typography>
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default SignUpForIndividuals;
