import * as React from 'react';
import {
	CssBaseline,
	Box,
	Typography,
	TextField,
	Container,
	Button,
	AppBar,
	Toolbar,
	IconButton,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { CloseOutlined } from '@material-ui/icons';
import Logo from '../MEF_Logo_PDC_01_small.jpg';
import Snackbar from '@material-ui/core/Snackbar';
import { SendPasswordResetEmail, baseURL } from '../../pocketbase/constants';
import axios from 'axios';
import AuthHeader from '../AuthHeader';

const ForgotPassword = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [snackbarMessageForError, setSnackbarMessageForError] = React.useState('');
	const [snackbarMessageForSuccess, setSnackbarMessageForSuccess] = React.useState('');
	let history = useHistory();

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		const data = new FormData(event.currentTarget);
		axios
			.get(`${baseURL}/api/collections/users/records?filter=(email='${data.get('email')}')`, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				if (res?.data?.items?.length > 0) {
					SendPasswordResetEmail(data.get('email'))
						.then((res) => {
							setSnackbarMessageForSuccess('Password reset email sent. Please check your inbox');
							setTimeout(() => {
								history.replace('/signin');
							}, 5000);
							setIsLoading(false);
						})
						.catch((err) => {
							const values: any = Object.values(err?.response?.data?.data);
							const keys: any = Object.keys(err?.response?.data?.data);
							if (values?.length) {
								setSnackbarMessageForError(`${keys[0]} : ${values[0]['message']}`);
							} else {
								setSnackbarMessageForError(err?.response.data?.message);
							}
							setIsLoading(false);
						});
				} else {
					setSnackbarMessageForError('Email not found. Please use the organisation email address');
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	return (
		<Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForError?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForError('');
				}}
				id="error"
				message={snackbarMessageForError}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForError('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snackbarMessageForSuccess?.length ? true : false}
				onClose={() => {
					setSnackbarMessageForSuccess('');
				}}
				id="success"
				autoHideDuration={4000}
				message={snackbarMessageForSuccess}
				action={
					<IconButton
						onClick={() => {
							setSnackbarMessageForSuccess('');
						}}
					>
						<CloseOutlined
							style={{
								color: '#fff',
							}}
							fontSize="small"
						></CloseOutlined>
					</IconButton>
				}
			/>

			<CssBaseline />
			<AuthHeader />

			<Box>
				<Box marginTop={10} paddingLeft={2}>
					<Typography
						color="primary"
						variant="h5"
						style={{
							fontWeight: 600,
						}}
					>
						Manchester Economic Forecasting - riskNZ
					</Typography>
				</Box>

				<Container component="main" maxWidth="sm">
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							minHeight: '70vh',
						}}
					>
						<Box
							style={{
								border: '1px solid #cccccc ',
								borderRadius: '12px',
								boxShadow: '10px 10px 8px #efefef',
								padding: '20px 24px',
							}}
						>
							<Typography component="h1" variant="h6">
								Forgot Password
							</Typography>
							<Box component="form" onSubmit={handleSubmit}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Organisation Email"
									name="email"
									autoFocus
									autoComplete="email"
								/>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									style={{
										margin: '20px 0px 4px',
									}}
									disabled={isLoading}
								>
									Send reset email
								</Button>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Box>
	);
};

export default ForgotPassword;
