import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import theme from '../../config/theme';

interface AccordionProps {
	title: any;
	categoryDescription: string;
	body?: any;
	color?: any;
	background?: any;
	accessIcon?: any;
	header?: boolean;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// marginBottom: '16px',
	},
	heading: {
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
	headingText: {
		fontSize: theme.typography.pxToRem(13),
	},
}));

const AccordionForInfoPage: React.FC<AccordionProps> = ({
	title,
	body,
	color,
	background,
	categoryDescription,
	accessIcon,
	header,
}) => {
	const classes = useStyles();
	const smUpScreen = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<div className={classes.root}>
			<Accordion
				elevation={1}
				style={{
					border: '1px solid rgba(0, 0, 0, .2)',
				}}
			>
				<AccordionSummary
					expandIcon={!header && <ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					style={{
						minHeight: 'min-content',
						height: smUpScreen ? '40px' : 'max-content',
						padding: 0,
						paddingLeft: 7,
						alignItems: smUpScreen ? 'center' : 'flex-start',
					}}
				>
					<Grid container spacing={2}>
						<Grid
							item
							xs={12}
							md={2}
							style={{
								background: background,
								paddingRight: smUpScreen ? 0 : 'initial',
							}}
						>
							<Typography
								className={classes.heading}
								style={{
									color: color,
								}}
							>
								{title} {!smUpScreen && accessIcon}
							</Typography>
						</Grid>
						{!header && smUpScreen ? (
							<Grid
								item
								xs={2}
								md={1}
								style={{
									textAlign: 'center',
								}}
							>
								{accessIcon}
							</Grid>
						) : (
							<Grid
								item
								xs={2}
								md={1}
								style={{
									textAlign: 'center',
									color: color,
									fontWeight: 'bold',
									display: 'flex',   
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								Access:
							</Grid>
						)}

						<Grid
							item
							xs={12}
							md={9}
							style={{
								paddingLeft: smUpScreen ? 0 : '8px',
							}}
						>
							<Typography
								className={classes.headingText}
								style={{
									color: color,
									marginLeft: header ? '5px' : '15px',
									fontWeight: header ? 'bold' : 'normal',
								}}
							>
								{categoryDescription}
							</Typography>
						</Grid>
					</Grid>
				</AccordionSummary>
				{!header && (
					<AccordionDetails>
						<Grid container spacing={1}>
							<Grid item xs={4} md={2} style={{ color: '#a08f4d', fontWeight: 600 }}>
								Tab Name
							</Grid>

							<Grid item xs={8} md={10} style={{ color: '#a08f4d', fontWeight: 600 }}>
								Tab Description
							</Grid>
							{body?.map((data: any, index: number) => (
								<>
									<Grid
										item
										xs={4}
										md={2}
										key={index}
										style={{ color: color, background: index % 2 == 0 ? background : '#fff' }}
									>
										<Typography
											style={{
												fontWeight: 600,
												fontSize: '0.875rem',
											}}
										>
											{data?.name}
										</Typography>
									</Grid>

									<Grid
										item
										xs={8}
										md={10}
										style={{ color: color, background: index % 2 == 0 ? background : '#fff', fontSize: '0.875rem' }}
									>
										{data?.description}{' '}
									</Grid>
								</>
							))}
						</Grid>
					</AccordionDetails>
				)}
			</Accordion>
		</div>
	);
};

export default AccordionForInfoPage;
