import { Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import SingleNewsCard from './InfoStartNewsSingle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface CardProps {
	imgSrc?: any | [];
	title?: any;
	subTitle?: any;
	body?: any;
	objectFit?: string;
	borderColor?: string;
}

const macroNews = [
	{
		thumbnail:
			'https://img.freepik.com/free-photo/beautiful-scenery-phragmites-plants-by-sea-with-swimming-pelican-sunset_181624-37787.jpg?w=2000',
		title: 'News Feed Headlines',
	},
	{
		thumbnail:
			'https://img.freepik.com/free-photo/beautiful-scenery-phragmites-plants-by-sea-with-swimming-pelican-sunset_181624-37787.jpg?w=2000',
		title: 'News Feed Headlines',
	},
];
const InfoStartNews: React.FC<CardProps> = ({ imgSrc, title, subTitle, body, objectFit, borderColor }) => {
	const [macroListings, setMacroListings] = useState([]);
	const [energyListings, setEnergyListings] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [url, setUrl] = useState('');

	const corsUrl = 'https://api.rss2json.com/v1/api.json?rss_url=';
	const getFeedListing = (url: any) => axios.get(`${corsUrl}${url}`);

	const getMacroListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					i === 0 ? (item.source = 'bls') : (item.source = 'bea');
					items.push(item);
				});
			}
			setMacroListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	const getEnergyListings = async (urlArray: any) => {
		let items: any = [];
		try {
			for (var i = 0; i < urlArray?.length; i++) {
				const response = await getFeedListing(urlArray[i]);
				response.data.items?.map((item: any) => {
					item.source = 'eia';
					items.push(item);
				});
			}
			setEnergyListings(items);
		} catch (ex) {
			console.log(ex);
		}
	};

	// Energy RSS Feeds
	// 'https://rss-feed-api.aws.jyllands-posten.dk/energywatch.eu/latest.xml'
	// 'https://renewablesnow.com/news/news_feed/?topic=technology.xml'
	//

	// GHG EMissions RSS Feeds - to be initiated
	//   https://feeds.emissieautoriteit.nl/nieuws.rss


	React.useEffect(() => {
		if (!initialized) {
			getMacroListings(['https://apps.bea.gov/rss/rss.xml']);
			//.rss link not working -  Maybe needs renaming to xml?
			// getMacroListings(['https://www.bls.gov/feed/bls_latest.rss', 'https://apps.bea.gov/rss/rss.xml']);
			getEnergyListings([
				'https://www.eia.gov/rss/todayinenergy.xml',
				'https://services.rechargenews.com/api/feed/rss',
			]);
			setInitialized(true);
		}
	}, []);

	return (
		<Card
			style={{
				boxShadow: '3px 3px 10px rgba(84,77,160,0.3) !important',
				borderRadius: '6px',
				border: '1px solid rgba(84,77,160,0.2)',
			}}
		>
			<CardContent
				style={{
					padding: '4px',
				}}
			>
				<Accordion
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						style={{
							minHeight: 'min-content',
							height: '40px',
							padding: 0,
							paddingLeft: 7,
							background: 'rgba(84,77,160,0.20)',
						}}
					>
						<Typography
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '4px',
								fontWeight: 'bold',
								color: 'rgba(84,77,160,1)',
								textTransform: 'uppercase',
							}}
						>
							Macro
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{macroListings?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>

				<hr
					style={{
						margin: '8px 0px 4px',
					}}
				/>
				<Accordion
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						style={{
							minHeight: 'min-content',
							height: '40px',
							padding: 0,
							paddingLeft: 7,
							background: 'rgba(221,110,16,0.2)',
						}}
					>
						<Typography
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '4px',
								fontWeight: 'bold',
								color: 'rgba(221,110,16,1)',
								textTransform: 'uppercase',
							}}
						>
							Energy
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{energyListings?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>

				<hr
					style={{
						margin: '8px 0px 4px',
					}}
				/>
				<Accordion
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						style={{
							minHeight: 'min-content',
							height: '40px',
							padding: 0,
							paddingLeft: 7,
							background: 'rgba(46,139,87,0.2)',
						}}
					>
						<Typography
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '4px',
								fontWeight: 'bold',
								color: 'rgba(46,139,87,1)',
								textTransform: 'uppercase',
							}}
						>
							GHG Emissions
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{macroNews?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>
				<hr
					style={{
						margin: '8px 0px 4px',
					}}
				/>

				<Accordion
					style={{
						border: '1px solid rgba(0, 0, 0, .2)',
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						style={{
							minHeight: 'min-content',
							height: '40px',
							padding: 0,
							paddingLeft: 7,
							background: 'rgba(0,115,237,0.2)',
						}}
					>
						<Typography
							variant="body2"
							style={{
								fontSize: '11px',
								marginBottom: '4px',
								fontWeight: 'bold',
								color: 'rgba(0,115,237,1)',
								textTransform: 'uppercase',
							}}
						>
							Finance/ESG
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						style={{
							display: 'block',
							padding: '4px',
						}}
					>
						{macroNews?.map((newsInfo: any, index: number) => (
							<SingleNewsCard newsInfo={newsInfo} key={index} />
						))}
					</AccordionDetails>
				</Accordion>
			</CardContent>
		</Card>
	);
};

export default InfoStartNews;
